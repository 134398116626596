.top-navigation-wrapper {
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 999;
  background: #ffffff;
  box-shadow: inset 0px -1px 0px var(--shadow);
}

/* top-navigation */
.top-navigation {
  position: relative;
  display: block;
  padding: 0;
}

/* header-top */
.header-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px 30px 13px;
}

/* logo */
.logo-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}
.logo-img img {
  width: 130px;
}

/* .header-bottom */
.header-bottom {
  background: var(--blue);
  padding: 11px 0;
}

/* menu */
.top-left-menu > a,
.top-left-menu .dropdown > a.nav-link {
  position: relative;
  display: inline-block;
  padding: 5px 10px;
  color: var(--white);
  cursor: pointer;
  margin-right: 10px !important;
  border-radius: 2px 2px 0px 0px;
  transition: all 0.3s linear;
}
.top-left-menu .dropdown > a.nav-link,
.top-left-menu .dropdown > a.nav-link:hover,
.top-left-menu .dropdown.show > a.nav-link {
  font-weight: 500 !important;
  font-size: 14px;
  line-height: 20px;
}
.top-left-menu > a:hover,
.top-left-menu > a.active,
.top-left-menu .dropdown > a.nav-link:hover,
.top-left-menu .dropdown.show > a.nav-link {
  color: var(--white);
  padding: 5px 10px;
}
.top-left-menu .dropdown > a > span.icon {
  margin-left: 8px;
}
.top-left-menu .dropdown .dropdown-toggle::after {
  display: none;
}
.top-left-menu .dropdown .dropdown-menu {
  padding: 8px 10px;
  background: var(--white);
  box-shadow: 0px 19px 44px rgb(75 102 133 / 25%);
  position: absolute;
  top: 40px;
  left: 0;
  border: 0;
}
.top-left-menu .dropdown .dropdown-menu a {
  display: block;
  padding: 5px 10px;
  font-weight: 600 !important;
  font-size: 14px;
  line-height: 20px;
  color: var(--midText);
  margin-bottom: 5px;
  transition: all 0.3s linear;
}
.top-left-menu .dropdown .dropdown-menu a:hover,
.top-left-menu .dropdown .dropdown-menu a.active {
  display: block;
  background: var(--lightGray);
}

/* top-menu-right */
.top-menu-right {
  display: flex;
  align-items: center;
}
.top_contact_call > h2 {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--midText);
}
.top_contact_call > h2 > img {
  margin-left: 10px;
}

.notification-bell {
  position: relative;
}

.notification-bell .badge {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: var(--orange);
  font-weight: 600;
  font-size: 7px;
  line-height: 7px;
  color: var(--white) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  right: 0;
}

/* profile-menu */
.profile-menu-img img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  object-fit: cover;
}

.top-user {
  margin-left: 20px;
}
.top-user button.btn-colorless {
  text-transform: inherit;
  text-decoration: underline;
}

.top-user button.btn > span,
.top-user a.btn > span {
  margin-left: 0px;
}
.top-user .btn.btn-mid,
.top-user .btn.btn-mid:hover,
.top-user .btn.btn-mid:focus {
  border-radius: 2px !important;
}

/* sidebar-toggle */
.top-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

/* responsive-filter */
.responsive-filter-serch {
  position: absolute;
  top: 63px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  background: var(--white);
}
.responsive-filter-serch .subscribe-from {
  display: flex !important;
}
.responsive-filter-serch .btn.btn-hero-subscribe,
.responsive-filter-serch .btn.btn-hero-subscribe:hover,
.responsive-filter-serch .btn.btn-hero-subscribe:focus {
  width: 40px;
  height: 40px;
}
.responsive-filter-serch .hero-search-form {
  height: 40px;
  background: var(--white);
}

/* top-user-popup */
.top-user-popup-list {
  margin: 0 0 15px;
}
.top-user-popup-list > li {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--border);
  border-radius: 5px;
  padding: 12px 10px;
  margin-bottom: 5px;
}
.single-top-user-popup-list-icon,
.single-top-user-popup-list-icon-wrapper > img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: var(--darkText);
  background: var(--bodyBg);
  overflow: hidden;
  margin-right: 15px;
}
.single-top-user-popup-list-txt > h4 {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: var(--darkText);
  margin: 0 0 5px;
}
.single-top-user-popup-list-txt > p {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: var(--midText);
}

/* responsive-menu */
.responsive-menu  .menu {
  display: block;
}
.responsive-menu .top-left-menu > a, 
.responsive-menu .top-left-menu .dropdown > a.nav-link {
  display: block !important;
  color: var(--darkText) !important;
  margin-bottom: 24px;
  margin-bottom: 8px !important;
}

.responsive-menu .top-left-menu > a:hover, 
.responsive-menu .top-left-menu .dropdown > a.nav-link:hover {
  color: var(--blue) !important;
}