/* course-tab-heading */
.course-tab-heading button {
  margin-right: 10px;
}
.course-tab-body {
  margin: 20px 0 0;
}
.course-tab-inner-body {
  padding: 30px 30px;
  background: var(--white);
  box-shadow: inset 0px -1px 0px var(--shadow);
  border-radius: 2px;
}
.contact-info-heading {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("../images/institute3.png") no-repeat center;
  background-size: cover;
  padding: 40px 0;
}
.contact-info-heading:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../images/blue-overlay.png") no-repeat center;
  background-size: cover;
}
.contact-info-heading > h2 {
  position: relative;
  color: var(--white);
}
.contact-info-body {
  margin: 20px 0 0;
}
.contact-info-body > ul > li {
  position: relative;
  display: flex;
}
.contact-info-body > ul > li:before {
  position: absolute;
  content: "";
  top: 14px;
  left: 7px;
  width: 1px;
  height: 100%;
  background: var(--border);
}
.contact-info-body > ul > li:last-child:before {
  display: none;
}
.contact-info-body > ul > li > .bullet {
  margin-right: 20px;
}
.contact-info-body > ul > li > h3.uniname {
  color: var(--darkText);
}
.contact-info-body > ul > li > h4 {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: var(--midText);
}
.contact-info-body > ul > li > h4.h4 {
  color: var(--darkText);
}
.contact-info-body > ul > li > h4.link-big {
  color: var(--sky);
}
.contact-info-body > ul > li {
  padding-bottom: 10px;
}
.contact-info-body > ul > li > h4 > span {
  font-weight: 700;
}
.single-course-icon {
  margin-right: 24px;
}
.single-search-item-feature p.course-tab-para {
  max-width: 555px;
}
.single-search-item-feature > ul > li {
  display: flex;
}
.course-list {
  margin-bottom: 10px;
}
.course-list > .bullet {
  margin-right: 15px;
}
.course-tab-inner-body .single-search-item-feature p {
  max-width: 535px;
}
