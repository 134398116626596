.app-width {
  max-width: 1415px;
  margin: 0 auto;
}
.pointer {
  cursor: pointer;
}

/* default-search */
.default-search {
  border: 1px solid var(--borderFour);
  width: 450px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  height: 50px;
  background: var(--white);
  padding: 0 8px 0 0;
}
.default-search.no-left-radius {
  border-left: 0px solid var(--borderFour);
}
.hero-search-form {
  height: 60px;
  width: 440px;
  margin-right: 10px;
  border-radius: 2px;
  border: 1px solid var(--color-less);
  box-shadow: 0px 24px 90px rgba(0, 0, 0, 0.1);
}

.bg-input-gray {
  border: 1px solid transparent;
  background: #f2f3f6;
}

.btn.btn-blue {
  background: var(--blue) !important;
  border: 2px solid var(--blue) !important;
}

.btn.btn-blue:hover,
.btn.btn-blue:focus {
  background: var(--colorless) !important;
  border: 2px solid var(--blue) !important;
  color: var(--blue) !important;
}

.btn.btn-subscribe,
.btn.btn-subscribe:hover,
.btn.btn-subscribe:focus {
  font-size: 12px !important;
  line-height: 18px !important;
  background: var(--deepSkyBlue) !important;
  border: 2px solid var(--deepSkyBlue) !important;
  padding: 0;
  height: 36px;
  width: 100px;
}

.btn.btn-subscribe:hover,
.btn.btn-subscribe:focus {
  background: var(--colorless) !important;
  border: 2px solid var(--deepSkyBlue) !important;
  color: var(--deepSkyBlue) !important;
}

.btn.btn-hero-subscribe,
.btn.btn-hero-subscribe:hover,
.btn.btn-hero-subscribe:focus {
  height: 56px;
  width: 158px;
  color: var(--white) !important;
  background: var(--deepSkyBlue) !important;
}

.btn.btn-search,
.btn.btn-search:hover,
.btn.btn-search:focus {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 230px;
}

a.btn.btn-search,
a.btn.btn-search:hover,
a.btn.btn-search:focus {
  color: var(--white);
}

a.btn.btn-search:hover,
a.btn.btn-search:focus {
  background: var(--colorless) !important;
  border: 2px solid var(--deepSkyBlue) !important;
  color: var(--deepSkyBlue) !important;
}

a.btn.btn-sky,
a.btn.btn-sky:hover,
a.btn.btn-sky:focus,
.btn.btn-sky,
.btn.btn-sky:hover,
.btn.btn-sky:focus {
  background: var(--deepSkyBlue) !important;
  color: var(--white) !important;
  font-weight: 600;
}

a.btn.btn-sky:hover,
a.btn.btn-sky:focus {
  background: var(--hoverOne) !important;
}

.btn.btn-consultant,
.btn.btn-consultant:hover,
.btn.btn-consultant:focus {
  background: var(--deepSkyBlue) !important;
  color: var(--white) !important;
  font-weight: 500;
  width: 155px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn.btn-consultant-reverse,
.btn.btn-consultant-reverse:hover,
.btn.btn-consultant-reverse:focus {
  background: var(--colorless) !important;
  color: var(--white) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn.btn-large,
.btn.btn-large:hover,
.btn.btn-large:focus,
a.btn.btn-large,
a.btn.btn-large:hover,
a.btn.btn-large:focus {
  font-weight: 600;
  font-size: 16px !important;
  line-height: 19px !important;
  padding: 15px 20px;
  border-radius: 2px !important;
}

.btn.btn-mid,
.btn.btn-mid:hover,
.btn.btn-mid:focus,
a.btn.btn-mid,
a.btn.btn-mid:hover,
a.btn.btn-mid:focus {
  font-weight: 700;
  font-size: 13px !important;
  line-height: 19px !important;
  padding: 10px 20px;
  border-radius: 60px !important;
}

.btn.btn-blackWhite,
.btn.btn-blackWhite:hover,
.btn.btn-blackWhite:focus {
  color: var(--btn-dark-text) !important;
  background: var(--color-less) !important;
  border: 1px solid var(--borderFive) !important;
}

.btn.btn-ligtGreen,
.btn.btn-ligtGreen:hover,
.btn.btn-ligtGreen:focus {
  color: var(--white) !important;
  background: var(--lightGreen) !important;
  border: 1px solid var(--lightGreen) !important;
}
.btn.btn-ligtGreen:hover,
.btn.btn-ligtGreen:focus {
  color: var(--white) !important;
  background: var(--green) !important;
  border: 1px solid var(--green) !important;
}

.btn.btn-green,
.btn.btn-green:hover,
.btn.btn-green:focus {
  color: var(--blueTwo) !important;
  background: var(--hoverTwo) !important;
  border: 1px solid var(--hoverTwo) !important;
}

.btn.btn-view-all,
.btn.btn-view-all:hover,
.btn.btn-view-all:focus {
  background: var(--white) !important;
  border: 1px solid var(--white) !important;
  width: 100%;
  color: var(--deepSkyBlue) !important;
  padding: 14px 0;
}

.btn.btn-view-all.blue-bg,
.btn.btn-view-all.blue-bg:hover,
.btn.btn-view-all.blue-bg:focus {
  background: var(--deepSkyBlue) !important;
  border: 1px solid var(--deepSkyBlue) !important;
  color: var(--white) !important;
  border-radius: 4px;
}

.btn.btn-blog,
.btn.btn-blog:hover,
.btn.btn-blog:focus {
  width: 125px !important;
  height: 40px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 24px !important;
}

.navbar-toggler {
  padding: 0;
  width: 68px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}
.navbar-light .navbar-toggler {
  color: var(--midText);
}

/* text */
h2.title {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: var(--darkText);
}

/* card */
.whats-app-section {
  position: relative;
  box-shadow: inset 0px -1px 0px var(--shadow);
  border-radius: 2px;
  overflow: hidden;
}
.whatsapp-gradient > img {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.whatsapp-bg > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.whatspp-body {
  max-width: 225px;
  padding: 23px 20px;
  position: relative;
}
.whatspp-body.width-100 {
  max-width: 100%;
}
.whatspp-body > h2 {
  color: #f2f3f6;
  margin-bottom: 10px !important;
}
.whatspp-body > p > img {
  margin-right: 10px;
}
.whatspp-body > p {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: var(--white);
}
.whatspp-body > p span {
  text-decoration: underline;
}

/* card-info */
.card-info {
  background: var(--white);
  /* box-shadow: inset 0px -1px 0px var(--shadow); */
  border-radius: 2px;
  padding: 30px 30px 10px;
  margin-bottom: 20px;
}
.card-info-pb-0 {
  padding-bottom: 0;
}
.card-info-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.card-info-heading .formik-select-wrapper {
  width: 135px;
}
.grid-customize .col-xl-3 {
  padding-right: 0;
}
.card-full-img > img {
  margin-bottom: 25px;
  border-radius: 8px;
}
.card-info-txt {
  margin-bottom: 25px;
}
.card-info-txt h3 {
  margin-bottom: 10px;
}
.card-info-txt p.para3 {
  margin-bottom: 10px;
}
.card-bullet-point > ul > li {
  display: flex !important;
}
.card-bullet-point > ul > li > .bullet {
  margin-right: 10px;
}
.card-bullet-point > ul > li p {
  margin-bottom: 5px !important;
}
.card-bullet-point > ul > li:last-child p {
  margin-bottom: 0px !important;
}
.single-card-list {
  position: relative;
  margin-bottom: 20px;
  transition: all 0.3s linear;
}
.single-card-img {
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;
}
.single-card-img > img {
  width: 100%;
  border-radius: 2px;
}
.single-card-img-info {
  position: absolute;
  background: var(--white);
  padding: 2px 5px;
  bottom: 15px;
  right: 15px;
  border-radius: 2px;
}
.single-card-img-info p {
  font-size: 12px;
  line-height: 18px;
  color: var(--darkText);
}
.single-card-img-info p > span {
  color: var(--green);
}
.single-card-body > h2 {
  color: var(--midText);
}
.card-info-footer {
  margin-bottom: 20px;
}
.scholership-title {
  margin-bottom: 5px;
  padding: 2px 8px;
  border-radius: 2px;
}
.scholership-title > h2 {
  color: var(--white);
}
.scholership-details {
  padding: 8px;
  border: 1px solid #dce5ea;
  margin-bottom: 10px;
}

/* circle-card-list */
.single-circle-card-img {
  padding: 45px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  overflow: hidden;
}
.single-circle-card-body {
  margin: 10px auto 0;
}
.single-circle-card-body > h2.h4 {
  color: var(--midText);
}

/* timeline-wrapper */
.timeline-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.timeline-row .MuiBox-root {
  width: 250px;
}
.timeline-section .MuiBox-root {
  width: 78%;
}
.timeline-row .single-cell {
  height: 40px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--midText);
  display: flex;
  align-items: center;
  justify-content: center;
}
.timeline-row .single-cell,
.timeline-row .empty-cell {
  width: 56px;
  text-align: center;
}
.timeline-section .single-cell,
.timeline-section .empty-cell {
  width: 130px;
}
.timeline-row .empty-cell {
  height: 22px;
}
.timeline-row .title {
  color: var(--midText);
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  padding-bottom: 4px;
}
.timeline-row .color-check-cell {
  width: 72px;
  height: 22px;
  border-radius: 50px;
}
.timeline-row .color-check-cell .icon {
  font-size: 16px;
}

/* modal */
.modal {
  z-index: 9999;
}
.modal-backdrop {
  z-index: 9999;
}
.modal-header {
  padding: 5px 15px;
  margin-bottom: 5px;
}
.auth-modal .modal-header {
  border-bottom: 0;
}

/* box */
ul.box-list {
  display: flex;
  flex-wrap: wrap;
}
ul.box-list > li.box {
  display: flex !important;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-size: 12px;
  width: 110px;
  height: 75px;
}
.box-list > .box {
  margin-right: 15px;
  margin-bottom: 10px;
}
.hr-bottom-line {
  border-bottom: 1px solid var(--border);
}

/* chip-info */
.chip-info {
  display: inline-block;
  padding: 0px 5px;
  background: var(--extraLightText);
  border-radius: 2px;
}
.chip-info span {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--darkText);
}

/* single-card-testimonial */
.single-card-testimonial {
  padding: 20px;
  border: 1px solid var(--gray);
  border-radius: 2px;
}
.single-testimonial-comment {
  margin-bottom: 20px;
}
.single-testimonial-comment > p {
  font-style: italic;
}
.single-testimonial-cite > h3 {
  color: var(--sky);
}

/* slick */
ul.slick-dots {
  display: flex !important;
  bottom: -5px;
  left: 45%;
}

/* section-title */
.section-title {
  text-align: center;
}
.section-title > h2 {
  font-weight: 600;
  font-size: 36px;
  line-height: 50px;
  text-align: center;
  color: var(--darkText);
}
.section-text > h2.title {
  font-weight: 700;
  font-size: 36px;
  line-height: 50px;
  color: var(--darkText);
}
.section-text > h3.sub-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: var(--darkText);
}
.section-text > p.para {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: var(--midText);
}

/* slick */
.slick-dots li.slick-active button:before {
  opacity: 1;
  color: var(--green);
}
