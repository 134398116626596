.app .main {
  min-height: 100vh;
}

.main-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: var(--black30);
  z-index: 9999;
  transition: 0.3s linear;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
}
.main-overlay.w-0 {
  opacity: 0;
  visibility: hidden;
}

.app .main .sidebar {
  z-index: 9999;
  position: fixed;
  top: 152px;
  left: 0;
  background: #ffffff;
  color: var(--black70);
  height: 78vh;
  overflow-y: auto;
  border-right: 1px solid var(--border);
  overflow-x: hidden;
  padding: 0 30px;
  transition: 0.3s linear;
}

.app .main .sidebar .sidebar-toggle {
  position: sticky;
  top: 0px;
  z-index: 9999;
  background: var(--deepSkyBlue);
}

.app .main .body {
  width: 100%;
  height: 100%;
}

.app .main .body .body-inner {
  width: 100%;
}

.main ul li {
  display: block;
}

/* others */
.width-85 {
  width: 100% !important;
}

.width-100 {
  width: 100%;
}

.width-15 {
  padding-left: 0px;
  width: 245px;
  transform: translateX(0px);
}

.width-0 {
  width: 245px;
  transform: translateX(-245px);
}

/* Scroll */
.app .main .sidebar::-webkit-scrollbar {
  width: 5px;
}

.app .main .sidebar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 8px #ffffff;
  box-shadow: inset 0 0 8px #ffffff;
}

.app .main .sidebar::-webkit-scrollbar-thumb {
  background-color: #e9e9e9;
}

/* layout-body */
.layout-scroll {
  /* height: 100vh; */
  height: calc(100vh - 17vh);
  overflow: hidden;
  overflow-y: scroll;
}
.layout-body {
  padding: 24px 0 0px;
}
.blank-left-side {
  margin-left: 260px;
}

/* card */
.card-sm-style {
  position: relative;
  padding: 20px;
  background: var(--white);
  box-shadow: inset 0px -1px 0px var(--shadow);
  border-radius: 2px;
  margin-bottom: 20px;
  overflow: hidden;
}
.card-sm-style > .title {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--border);
}
.card-sm-style > .title > h2 {
  color: var(--darkText);
}
.card-sm-body {
  height: 300px;
  margin: 10px 0 0;
}
