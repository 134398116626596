.search-section {
  margin: 5px 0 50px;
  padding: 0 15px;
}

/* search-section-heading */
.search-section-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 22px;
}
.search-section-heading > h2,
.search-section-heading .icon {
  color: var(--darkText);
}
.search-section-heading > h2.h1 > span {
  text-decoration: underline;
  color: var(--sky);
}

/* search-section-body */
.single-search-item {
  padding: 20px;
  background: var(--white);
  border-radius: 2px;
  margin-bottom: 20px;
  height: 430px;
  overflow: hidden;
  transition: 0.3s linear;
}
.single-search-item:hover {
  box-shadow: inset 0px -1px 0px var(--shadow);
}
.single-search-item-heading {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--border);
}
.single-search-item-heading > ul {
  display: flex;
  align-items: center;
}
.single-search-item-heading > ul > li {
  display: inline-block;
}
.single-search-item-heading-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  background: #f7fbff;
  border: 1px solid #dcefff;
  margin-right: 20px;
  border-radius: 50%;
}
.single-search-item-heading h2 {
  color: var(--darkText);
}
.single-search-item-footer {
  margin: 12px 0 0;
}
.single-search-item-footer > ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.ssi-box {
  width: 32px;
  height: 32px;
  background: #f2f3f6;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.single-search-icon-list > ul {
  display: flex;
  align-items: center;
}
.single-search-icon-list > ul > li {
  padding-left: 20px;
  border-left: 1px solid var(--borderOne);
}
.single-search-icon-list > ul > li:first-child {
  padding: 0;
  padding-right: 20px;
  border: 0;
}
.single-search-item-feature {
  margin-bottom: 10px;
}
.single-search-item-feature h3 {
  color: var(--darkText);
  margin-bottom: 5px !important;
}
.single-search-item-feature p,
.single-search-item-feature > p > a {
  color: var(--midText);
}
.single-search-item-feature > p > a {
  color: var(--sky);
  word-break: break-all;
}
.single-search-item-feature h4 {
  display: inline-block;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: var(--darkText);
  padding: 3px 5px;
  background: #93ff6d;
  border-radius: 2px;
}
.single-search-item-feature h4.half-funding {
  background: #b3edfd;
}
.single-search-item-inner-body-left {
  border-right: 1px solid var(--border);
}
.single-search-item-feature-wrapper {
  margin: 20px 0 0;
}
.single-search-item-footer .btn.btn-mid,
.single-search-item-footer .btn.btn-mid:hover,
.single-search-item-footer .btn.btn-mid:focus,
.single-search-item-footer a.btn.btn-mid,
.single-search-item-footera.btn.btn-mid:hover,
.single-search-item-footer a.btn.btn-mid:focus {
  border-radius: 2px !important;
}
