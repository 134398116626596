/*==================================
* Author        : "Scholiguide"
* Template Name :  Scholiguide Team
* Version       : 1.0
==================================== */

/*=========== TABLE OF CONTENTS ===========

==========================================*/

/*==================================
font-family: 'Roboto', sans-serif;
==================================== */

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

/*-------------------------------------
	variable
--------------------------------------*/
:root {
  --primaryFont: "Roboto", sans-serif !important;

  /* text color */
  --darkText: #1c2b3c !important;
  --midText: #3d3d3d !important;
  --lightText: #7a7a7a !important;
  --textGray: #dfdfdf !important;
  --extraLightText: #eaeaea !important;
  --btn-dark-text: #2d2d2d !important;
  --dark-text-two: #1d1e20 !important;
  --dark-text-three: #474747 !important;

  /* border */
  --border: #dfdfdf !important;
  --borderOne: #efefef !important;
  --borderTwo: #e5e5e5 !important;
  --borderThree: #d3d3d3 !important;
  --borderFour: #c4c4c4 !important;
  --borderFive: #dbdbdb !important;
  --borderSix: #dce5ea !important;
  --borderSeven: #D9D9D9 !important;

  /* shadow */
  --shadow: #dce5ea !important;

  /* primary */
  --blue: #053680 !important;
  --darkBlue: #12034b !important;
  --blueBtn: #063781 !important;
  --sky: #3aa2f8 !important;

  /* secondary */
  --darkBlack: #2f3448 !important;
  --orange: #ff842b !important;
  --darkOrange: #e36131 !important;
  --skyBlue: #5d9dff !important;
  --purple: #9d8ef9 !important;
  --deepSkyBlue: #00c3f9 !important;
  --neoGreen: #93ff6d !important;
  --mediumPurple: #ba84ff !important;
  --green: #00ab6a !important;
  --greenDark: #00832c !important;
  --indigo: #936dfc !important;

  /* tertiary */
  --orangeLight: #fbe6d7 !important;
  --deepSkyBlueLight: #b3edfd !important;
  --meritimeBlue: #117bd2 !important;

  --primayColor: #474747 !important;
  --skyBg: #cceafd !important;

  /* hover-color */
  --hoverOne: #1193ff !important;
  --hoverTwo: #00ea96 !important;

  /* color */
  --white: #ffffff !important;
  --black: #1c2b3c !important;
  --pureBlack: #000 !important;
  --red: #ff2f22 !important;
  --gray: #dfdfdf !important;
  --blueTwo: #063781 !important;
  --lightGray: #f4f4f4 !important;
  --lightGreen: #6fbe7b !important;
  --deepPink: #c21779 !important;
  --whatsApp: #1ebea5 !important;
  --twitter: #00a8e7 !important;
  --color-less: transparent !important;

  --black30: rgba(0, 0, 0, 0.3) !important;

  --error: #b72136 !important;

  --bodyBg: #f8f8f8 !important;
  --toastify-z-index: 99999;

  /* bg */
  --bgOne: #f4f7f9 !important;
}

/*-------------------------------------
	General css (Reset code)
--------------------------------------*/
*,
p,
a,
span,
div,
label {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  font-family: var(--primaryFont);
}

.table thead th {
  vertical-align: middle;
}

body {
  background: var(--white) !important;
  color: var(--midText);
  font-size: 16px;
  /* max-width: 1920px; */
  margin: 0 auto;
  overflow-x: hidden;
}

a,
a:hover,
a:active,
a:focus {
  display: inline-block;
  text-decoration: none;
  color: var(--midText);
  font-size: 16px;
  font-weight: 500;
}

a:hover,
a:active,
a:focus {
  color: var(--sky);
}

h1,
h2,
h3,
h4,
h5,
h6,
h2 > a,
h3 > a,
h4 > a,
h5 > a,
h6 > a,
h2 > a:hover,
h3 > a:hover {
  margin: 0;
  text-transform: initial;
}

h2 > a:hover,
h3 > a:hover,
li > a:hover {
  margin: 0;
  color: var(--deepSkyBlue);
  font-size: 16px;
  text-transform: initial;
}

p {
  margin: 0;
  font-size: 16px;
  color: var(--midText);
  font-weight: 400;
  line-height: 28px;
}

img {
  border: none;
  max-width: 100%;
  height: auto;
  border-radius: 2px;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

ul li {
  list-style: none;
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
}

select,
input,
textarea,
button,
.form-control,
.form-control:focus,
input:focus {
  box-shadow: none;
  outline: 0 !important;
}

input::placeholder {
  font-size: 14px;
  color: var(--lightText);
}

/* button */
button.btn,
button.btn:hover,
button.btn:focus,
a.btn,
a.btn:hover,
a.btn:focus {
  font-size: 14px !important;
  line-height: 26px !important;
  text-transform: capitalize;
  border-radius: 2px !important;
  transition: 0.3s linear;
  box-shadow: none !important;
}
button.btn > span,
a.btn > span {
  position: relative;
  top: -1px;
  margin-left: 10px;
}
button.btn.btn-large,
button.btn.btn-large:hover,
button.btn.btn-large:focus {
  padding: 20px 25px;
}
button.btn.btn-medium,
button.btn.btn-medium:hover,
button.btn.btn-medium:focus,
a.btn.btn-medium,
a.btn.btn-medium:hover,
a.btn.btn-medium:focus {
  font-weight: 500 !important;
  padding: 8px 16px;
  font-size: 14px !important;
  height: 40px;
}
button.btn.btn-small,
button.btn.btn-small:hover,
button.btn.btn-small:focus {
  font-weight: 500 !important;
  padding: 6px 20px;
  font-size: 14px !important;
}
.btn.btn-tab,
.btn.btn-tab:hover,
.btn.btn-tab:focus {
  font-weight: 600;
  font-size: 14px !important;
  line-height: 20px !important;
  padding: 4px 15px;
}
.btn.btn-tab.btn-blog-tab,
.btn.btn-tab.btn-blog-tab:hover,
.btn.btn-tab.btn-blog-tab:focus {
  font-weight: 600;
  font-size: 13px !important;
  line-height: 19px !important;
  padding: 10px 20px;
}
button.btn.btn-white,
button.btn.btn-white:hover,
button.btn.btn-white:focus {
  background: var(--white) !important;
  border: 1px solid var(--white) !important;
  color: var(--darkText) !important;
}
button.btn.btn-orange,
button.btn.btn-orange:hover,
button.btn.btn-orange:focus {
  background: var(--orange) !important;
  border: 1px solid var(--orange) !important;
  color: var(--white) !important;
}
button.btn.btn-darkOrange,
button.btn.btn-darkOrange:hover,
button.btn.btn-darkOrange:focus {
  background: var(--darkOrange) !important;
  border: 1px solid var(--darkOrange) !important;
  color: var(--white) !important;
}
button.btn.btn-sky-outline {
  border: 1px solid var(--sky) !important;
  color: var(--sky) !important;
}
button.btn.btn-sky,
button.btn.btn-sky:hover,
button.btn.btn-sky:focus {
  background: var(--sky) !important;
  border: 1px solid var(--sky) !important;
  color: var(--white) !important;
}
button.btn.btn-sky:hover,
button.btn.btn-sky:focus {
  background: var(--hoverOne) !important;
}

button.btn.btn-blue,
button.btn.btn-blue:hover,
button.btn.btn-blue:focus,
a.btn.btn-blue,
a.btn.btn-blue:hover,
a.btn.btn-blue:focus {
  background: var(--blue) !important;
  border: 1px solid var(--blue) !important;
  color: var(--white) !important;
}
button.btn.btn-blue:hover,
button.btn.btn-blue:focus,
a.btn.btn-blue:hover,
a.btn.btn-blue:focus {
  color: var(--blue) !important;
  background: var(--color-less) !important;
  border: 1px solid var(--blue) !important;
}
button.btn.btn-default,
button.btn.btn-default:hover,
button.btn.btn-default:focus {
  background: var(--orange) !important;
  border: 1px solid var(--orange) !important;
  color: var(--white) !important;
}
button.btn.btn-default:hover,
button.btn.btn-default:focus {
  background: var(--orange) !important;
}
button.btn.btn-courseTab,
button.btn.btn-courseTab:hover,
button.btn.btn-courseTab:focus {
  background: var(--white) !important;
  border: 1px solid var(--gray) !important;
  color: var(--midText) !important;
}
button.btn.btn-courseTab:hover,
button.btn.btn-courseTab:focus {
  background: var(--orange) !important;
  background: var(--white) !important;
}
button.btn.btn-blackWhite,
button.btn.btn-blackWhite:hover,
button.btn.btn-blackWhite:focus {
  background: var(--color-less) !important;
  border: 1px solid 1px solid rgba(47, 52, 72, 0.16) !important;
  color: var(--dark-text) !important;
}
button.btn.btn-blackWhite:hover,
button.btn.btn-blackWhite:focus {
  background: var(--blue) !important;
  color: var(--white) !important;
}
button.btn.btn-colorless,
button.btn.btn-colorless:hover,
button.btn.btn-colorless:focus {
  background: var(--color-less) !important;
  border: 1px solid var(--color-less) !important;
  color: var(--midText) !important;
}
button.btn.btn-neoGreen,
button.btn.btn-neoGreen:hover,
button.btn.btn-neoGreen:focus {
  background: var(--neoGreen) !important;
  border: 1px solid var(--neoGreen) !important;
  color: var(--blueBtn) !important;
}
.btn.btn-tab.active {
  background: var(--sky) !important;
  border: 1px solid var(--sky) !important;
  color: var(--white) !important;
}
.btn.btn-tab.btn-blog-tab.active {
  background: var(--blue) !important;
  border: 1px solid rgba(47, 52, 72, 0.16) !important;
  color: var(--white) !important;
}

button.btn.btn-load,
button.btn.btn-load:hover,
button.btn.btn-load:focus {
  background: var(--white) !important;
  border: 1px solid var(--gray) !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: var(--midText) !important;
  padding: 6px 20px;
  cursor: pointer;
  border-radius: 2px !important;
  transition: all 0.5s linear;
}
button.btn.btn-modal-cross,
button.btn.btn-modal-cross:hover,
button.btn.btn-modal-cross:focus {
  border: 0px solid var(--color-less) !important;
}
.btn.btn-contact,
.btn.btn-contact:hover,
.btn.btn-contact:focus {
  font-weight: 600;
  font-size: 16px !important;
  line-height: 19px !important;
  padding: 0px;
  width: 233px;
  height: 62px;
  border-radius: 2px !important;
}

[placeholder]:focus::-webkit-input-placeholder {
  -webkit-transition: opacity 0.3s 0.3s ease;
  -moz-transition: opacity 0.3s 0.3s ease;
  -ms-transition: opacity 0.3s 0.3s ease;
  -o-transition: opacity 0.3s 0.3s ease;
  transition: opacity 0.3s 0.3s ease;
  opacity: 0;
}

[placeholder] {
  color: var(--lightText);
  font-size: 14px;
  line-height: 24px;
}

/* scrollbar */
.scrollbar-design::-webkit-scrollbar {
  background-color: #e9e9e9;
  height: 2px;
}

.scrollbar-design::-webkit-scrollbar-thumb {
  background-color: #e9e9e9;
  border-radius: 2px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-remove::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-remove {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* scrollbar */
::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

/* ::-webkit-scrollbar-button {
  background: var(--bodyBg);
} */

::-webkit-scrollbar-track-piece {
  background: var(--bodyBg);
}

::-webkit-scrollbar-thumb {
  background: #e9e9e9;
  border-radius: 10px;
}

/*-------------------------------------
	Common Class
--------------------------------------*/
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.overflowY {
  overflow-y: scroll;
}

.center {
  text-align: center;
}

.btn.border-8,
.btn.border-8:hover {
  border-radius: 8px !important;
}

.go-back {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--midText);
  cursor: pointer;
}

/*-------------------------------------
	Typography
--------------------------------------*/
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 500;
  color: var(--darkText);
  margin: 0 !important;
  transition: 0.3s linear;
}

.fw400,
.h3,
.h5,
.h6 {
  font-weight: 400 !important;
}
.fw500,
.h1,
.h2,
.h4,
.para-bold {
  font-weight: 500 !important;
}
.fw600,
.h4-bold,
.h5-bold,
a.h5-bold,
a.h5-bold:hover,
a.h5-bold:focus {
  font-weight: 600 !important;
}
.fw700,
.h3-bold {
  font-weight: 700 !important;
}

.h1 {
  font-size: 40px;
  line-height: 56px;
}
.h2,
a.h2:hover,
a.h2:focus {
  font-size: 32px;
  line-height: 48px;
}
.h3 {
  font-size: 20px;
  line-height: 28px;
}
.h4 {
  font-size: 16px;
  line-height: 24px;
}
.h5,
a.h5,
a.h5:hover,
a.h5:focus {
  font-size: 14px;
  line-height: 20px;
}
.h6 {
  font-size: 12px;
  line-height: 18px;
}

.para1 {
  font-size: 20px;
  line-height: 34px;
}
.para2 {
  font-size: 18px;
  line-height: 30px;
}
.para3 {
  font-size: 16px;
  line-height: 28px;
}
.para4,
a.para4:hover,
a.para4:focus {
  font-size: 14px;
  line-height: 22px;
}
a.link-big,
a.link-big:hover,
a.link-big:focus {
  position: relative;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: var(--sky);
  transition: 0.3s linear;
}
a.link-small,
a.link-small:hover,
a.link-small:focus {
  position: relative;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: var(--sky);
  transition: 0.3s linear;
}
a.link-small:before,
a.link-big:before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  transform: scaleX(0);
  background: var(--sky);
  transition: 0.3s linear;
  transform-origin: 0% 100%;
}
a.link-big:hover:before,
a.link-small:hover:before {
  transform: scaleX(1);
}

/*-------------------------------------
	color
--------------------------------------*/
.blue,
.blue:hover {
  color: var(--blue);
}
.darkText {
  color: var(--darkText) !important;
}
.sky {
  color: var(--sky) !important;
}
.orange {
  color: var(--orange) !important;
}
.purple {
  color: var(--purple) !important;
}
.green {
  color: var(--green) !important;
}
.greenDark {
  color: var(--greenDark);
}
.darkBlack {
  color: var(--darkBlack);
}

/*-------------------------------------
  bg
--------------------------------------*/
.bgOne {
  background: var(--bgOne) !important;
}
.bgBlue {
  background: var(--blue);
}
.bgIndigo {
  background: var(--indigo);
}

/*-------------------------------------
  slider
--------------------------------------*/
a.carousel-control-next,
a.carousel-control-next:hover,
a.carousel-control-next:focus a.carousel-control-prev,
a.carousel-control-prev:hover,
a.carousel-control-prev:focus {
  display: flex;
  align-items: center;
  justify-content: center;
}
.carousel-control-next,
.carousel-control-prev {
  top: auto;
  bottom: 0;
  color: var(--green);
  opacity: 1;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  border: 1px solid var(--green);
  transition: 0.3s linear;
}
.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: var(--white);
  background: var(--green);
  opacity: 1;
}
.carousel-control-next {
  left: 56px;
}

.animate-bounce {
  animation: bounce 1s infinite;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

/* custom-button-group */
.custom-button-group {
  display: inline-flex;
  border-radius: 4px;
}
.btn-single-groupe:not(:last-of-type) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-color: transparent;
}
.btn-single-groupe.no-left-radius {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0px solid va(--white);
}
.custom-button-group .form-container {
  margin: 0;
}
