/*
  =================================
        General
  =================================
*/

.form-container {
  margin-bottom: 5px;
}

.form-group {
  margin-bottom: 0;
  background: var(--white);
}

/*
    =================================
          Login Input
    =================================
  */

/* parent */
.login-input {
  position: relative;
  z-index: 0;
  border-radius: 2px;
}

.login-input input,
.login-input textarea {
  height: 40px;
  width: 100%;
  border-radius: 2px;
  margin-bottom: 0px !important;
}

.login-input.textarea-field textarea {
  height: 175px;
  padding: 13px 15px !important;
}

/* normal */
.login-input input.form-control,
.login-input input.form-control:focus,
.login-input input.form-control:hover,
.login-input textarea.form-control,
.login-input textarea.form-control:focus,
.login-input textarea.form-control:hover {
  position: relative;
  background: transparent;
  color: var(--lightText);
  padding-left: 20px;
  padding-top: 0px;
  background: transparent !important;
  border: 1px solid var(--border) !important;
  transition: all 0.3s ease-in-out;
}

.login-input.bg-input-gray input.form-control {
  border: 1px solid var(--colorless) !important;
}

.login-input label {
  position: absolute;
  top: 4px;
  padding: 15px 0 0 13px;
  transition: all 200ms;
  left: 23px;
  white-space: nowrap;
  max-width: 300px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: var(--black40);
  overflow: hidden;
}

/* hover */
.login-input input.form-control:hover,
.login-input textarea.form-control:hover {
  border: 1px solid var(--lightText) !important;
}

/* clicked */
.login-input input.form-control:focus,
.login-input textarea.form-control:focus {
  border: 1px solid var(--lightText) !important;
}

.onFocusLabel,
.error-label.isValue {
  font-size: 75%;
  transform: translate3d(0, 2px, 0);
  font-size: 14px !important;
  line-height: 20px !important;
  color: var(--lightText) !important;
  top: 10px !important;
  background: transparent;
  padding: 0px 0px 0px 30px !important;
  z-index: 2;
}

/* enable */
.login-input input.form-control.isValue,
.login-input textarea.form-control.isValue {
  background-color: transparent !important;
}

.login-input .form-icon {
  color: var(--lightText);
  position: absolute;
  top: 7px;
  left: 13px;
  z-index: 1;
}

.error-label.isValue,
.login-input .form-icon.isValue {
  color: var(--midText) !important;
}

/* error */
.login-input input.form-control.error-form-control,
.login-input textarea.form-control.error-form-control {
  border: 1px solid var(--error) !important;
  box-shadow: none !important;
  padding-top: 0px !important;
}

.login-input input.form-control.error-form-control:focus,
.login-input textarea.form-control.error-form-control:focus {
  box-shadow: none !important;
}

/*  disabled */
.onFocusLabel.is-disabled {
  color: rgba(0, 0, 0, 0.2) !important;
}

.login-input .form-control:disabled,
.login-input .form-control:disabled:hover {
  color: rgba(0, 0, 0, 0.7) !important;
  background-color: rgba(33, 33, 33, 0.08) !important;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
}

.login-input .form-trail-icon {
  color: var(--midText);
  left: auto;
  right: 15px;
}
.login-input .form-lead-icon {
  margin-right: 8px;
}
.input-large .form-icon {
  top: 16px;
}

.login-input .form-icon.active,
.login-input .form-icon.form-trail-icon.active {
  color: var(--deepSkyBlue);
}

.form-control:disabled,
.form-control:disabled:hover {
  color: rgba(0, 0, 0, 0.7) !important;
  background-color: rgba(33, 33, 33, 0.08) !important;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
}

.onBlurLabel,
.error-label {
  font-size: 100%;
  transform: translate3d(0px, 0px, 0px);
  background-color: transparent;
  padding: 19px 0px 0px 30px !important;
}

.error-label,
.error,
.error-form-icon,
.form-trail-icon-error {
  color: var(--error) !important;
  opacity: 1 !important;
}

.formik-select input,
.formik-select textarea {
  border: 0 !important;
}

.input-xl.lead-icon-padding input.form-control,
.input-xl.lead-icon-padding input.form-control:focus,
.input-xl.lead-icon-padding input.form-control:hover,
.input-xl.lead-icon-padding textarea.form-control,
.input-xl.lead-icon-padding textarea.form-control:focus,
.input-xl.lead-icon-padding textarea.form-control:hover {
  padding-left: 30px !important;
}

/*
    =================================
          Input Xl
    =================================
  */
.input-xl input {
  height: 54px;
}

.input-xl input.form-control,
.input-xl input.form-control:focus,
.input-xl input.form-control:hover,
.input-xl textarea.form-control,
.input-xl textarea.form-control:focus,
.input-xl textarea.form-control:hover {
  padding-left: 15px !important;
  font-size: 14px;
  line-height: 20px;
  padding: 0;
}

.input-xl label {
  left: 12px;
}

.input-xl .onBlurLabel,
.input-xl .error-label {
  padding: 13px 0px 0px 0px !important;
}

.input-xl .onFocusLabel,
.input-xl .error-label.isValue {
  top: 6px !important;
  padding: 0px 0px 0px 0px !important;
}

/*
    =================================
          Input Small
    =================================
  */
.input-sm input,
.search-input input {
  height: 36px;
}
.default-input input {
  height: 40px;
  width: 365px;
  margin-right: 10px;
}
.input-sm input[type="number"]::placeholder {
  font-size: 12px;
}

/*
    =================================
          Search Input
    =================================
  */
.search-input.fixed-width {
  width: 250px;
}

.login-input.search-input label {
  font-size: 12px;
  line-height: 14px;
}

.search-input input.form-control,
.search-input input.form-control:focus,
.search-input input.form-control:hover {
  padding-top: 8px;
}

.search-input .form-icon {
  top: 3px;
}

.search-inner-input {
  padding-right: 40px;
}

/*
    =================================
        Input Number
    =================================
  */
.input-number {
  position: relative;
  overflow: hidden;
}

.number-button-group {
  position: absolute;
  top: 2px;
  right: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 60%;
  width: 30px;
  z-index: 1;
  background: #ffffff !important;
}

/*
    =================================
          Textarea
    =================================
  */
.form-group.login-input.input-xl.textarea {
  overflow-x: hidden;
}

.textarea textarea {
  height: 96px;
}

.input-xl.textarea label {
  background: #fff;
  width: 90%;
  max-width: initial;
}

.input-xl.textarea .onFocusLabel,
.input-xl.textarea .error-label.isValue {
  top: -1px !important;
  height: 25px;
  display: flex;
  align-items: center;
}

.input-xl.textarea-with-label textarea.form-control,
.input-xl.textarea-with-label textarea.form-control:focus,
.input-xl.textarea-with-label textarea.form-control:hover {
  padding-top: 6px !important;
}

.input-xl.textarea .onFocusLabel.is-disabled {
  background: transparent !important;
}

/*
    =================================
        Select
    =================================
  */
.formik-select-wrapper {
  position: relative;
}

/* lebel-bold */
.lebel-bold {
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: var(--black60);
}

.formikSelect-icon {
  color: rgba(0, 0, 0, 0.5);
  width: 20px;
  height: 20px;
}

.formikSelect-icon-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: 0.1px;

  color: rgba(0, 0, 0, 0.7);
}

/* selection */
.MuiTypography-root {
  font-size: 13px !important;
}

/* input-field-main */
.input-field-main label {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--midText);
  margin-bottom: 9px;
}
label.label {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--sky);
  margin-bottom: 8px;
}
.hero-form-list > li > .input-field-main label {
  font-weight: 600;
  color: var(--white);
  margin-bottom: 10px;
}
