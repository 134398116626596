/* about-hero-section */
.hero-section-body.about-section-body > p {
  color: var(--midText);
}
.about-hero-section {
  padding: 30px 0 0;
}
.hero-section-person-img.about-section-person-img {
  display: flex;
}
.about-section-person-img img.right-img {
  position: relative;
  right: 87px;
}

/* scholarship-portal-section */
.scholarship-portal-section {
  margin: 80px 0 55px;
}
.portal-info-body {
  margin: 30px 0 0;
}
.portal-info-body .card-full-img > img {
  width: 100%;
  border-radius: 8px;
}

/* .core-points-info  */
.core-points-info {
  padding: 80px 0 50px;
}
.core-points-info-body {
  padding: 30px 0 0;
}
.single-card-point-info {
  max-width: 640px;
}
.single-card-point-info .card-full-img > img {
  width: 100%;
}

.about-card .card-info-txt h3 {
  margin-bottom: 10px !important;
}

/* single-team */
.team-section {
  padding: 80px 0;
}
.team-section-content-body {
  padding: 30px 0px 0px;
}
.single-team-card {
  text-align: center;
  margin-bottom: 20px;
}
.single-team-card-img {
  margin-bottom: 20px;
}
.single-team-card-img.noImg img {
  height: 235px;
}
.single-team-card-txt {
  text-align: center;
}

/* about-hm-review */
.hm-student-review-section.about-hm-review {
  margin: 0;
  padding: 70px 0 80px;
}
.about-hm-review .hm-student-review-inner {
  background: var(--white);
}

/* letter-section */
.letter-section {
  margin: 80px 0 55px;
}
.letter-section-content .card-info-heading {
  justify-content: center;
  margin-bottom: 25px;
}
.single-letter-info {
  max-width: 605px;
}
.letter-info-body .card-full-img > img {
  margin-bottom: 20px !important;
}
.letter-footer {
  margin: 20px 0 0;
}
.letter-footer-signeture-img {
  margin: 10px 0;
}

/* single-card-testimonial */
.testimonial-info-body .single-card-testimonial {
  margin: 0 10px 20px;
}

/* core-points-txt */
.card-info.core-points-info {
  padding-bottom: 0 !important;
}
.core-points-info-body .card-info-txt {
  margin-bottom: 30px;
}
.core-points-txt {
  display: flex;
}
.core-points-txt > h2 {
  border-right: 1px solid var(--border);
  padding-right: 20px;
  margin-right: 20px !important;
}
