.auth-login-wrapper {
  width: 565px;
  text-align: center;
  background: var(--white);
  border-radius: 5px;
}

.auth-login-body {
  max-width: 365px;
  margin: 20px auto;
}

.auth-modal .modal-content {
  padding: 40px 0 35px;
}

.auth-sign-body h2.auth-title {
  margin: 0 0 30px !important;
}

.auth-signin-btn {
  border: none;
  margin-bottom: 30px;
}

.auth-signin-btn > img {
  cursor: pointer;
}

p.auth-subtitle,
p.auth-subtitle-para {
  position: relative;
}

p.auth-subtitle-para {
  border-bottom: 1px solid var(--borderThree);
  padding-bottom: 20px !important;
  margin-bottom: 20px !important;
}

.auth-login-body p.auth-subtitle {
  margin-bottom: 20px !important;
}

.auth-login-body p.auth-subtitle:before {
  position: absolute;
  content: "";
  top: 50%;
  left: 0;
  width: 99px;
  height: 1px;
  background: var(--gray);
}

.auth-login-body p.auth-subtitle:after {
  position: absolute;
  content: "";
  top: 50%;
  right: 0;
  width: 99px;
  height: 1px;
  background: var(--gray);
}

.auth-login-body .default-button {
  width: 100%;
  margin-bottom: 20px;
}

p.auth-para {
  color: var(--midText);
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

p.auth-footer-para > span {
  color: var(--deepPink);
  cursor: pointer;
}

.auth-login-wrapper label.label {
  text-align: left;
}

.auth-forget-section {
  margin-bottom: 10px;
}

.auth-forget {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: var(--midText);
}

.auth-label {
  text-align: left;
}

.auth-footer-sign-para {
  margin: 15px 0 0;
}

.auth-footer-sign-para > span {
  color: var(--skyBlue) !important;
  text-decoration: underline;
}

.auth-footer-button {
  margin: 20px 0;
}
