/* blog-main-info-content */
.blog-main-info-content {
  padding: 30px;
  background: #f9fbfc;
  border-radius: 8px;
}
.blog-main-info {
  margin: 80px 0 20px;
}
.blog-main-info-img > img {
  width: 100%;
  margin: 0 0 20px;
  border-radius: 8px;
}
.blog-main-info-txt {
  max-width: 530px;
}
.blog-main-info-txt.blog-share-groupe {
  max-width: 100%;
}
.blog-main-info-txt > h3.sub-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: var(--blue);
  margin-bottom: 20px;
}
.blog-main-info-txt > h2 > a {
  font-weight: 600;
  font-size: 36px;
  line-height: 50px;
  color: var(--darkText);
  font-weight: 600 !important;
  margin-bottom: 0px !important;
  transition: all 0.3s linear;
}
.blog-main-info-txt > p.h4 {
  font-size: 16px;
  line-height: 28px;
  font-weight: 400 !important;
  color: #6d6e76 !important;
  margin-bottom: 30px !important;
}
.blog-main-info-txt .single-person {
  margin-bottom: 24px;
}
.blog-main-info-txt .single-person-img {
  margin-right: 10px;
}
.blog-main-info-txt .single-person-img > img {
  width: 46px;
  height: 46px;
  border-radius: 50%;
}
.blog-main-info-txt .single-person-body h3,
.blog-main-info-txt .single-person-body h3 > a,
.blog-main-info-txt .single-person-body h3 > a:hover,
.blog-main-info-txt .single-person-body h3 > a:focus {
  font-weight: 700 !important;
  color: var(--blue);
  margin: 0 !important;
}
.blog-main-info-txt .single-person-body h6 {
  color: var(--lightText);
}

/* blog-card section */
.blog-card-section {
  margin: 60px 0 40px;
}
.single-blog-txt > h2 > a,
.single-blog-txt > h2 > a:hover,
.single-blog-txt > h2 > a:focus {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: var(--darkText);
  margin-bottom: 20px;
  transition: all 0.3s linear;
}
.single-blog-txt > h2 > a:hover,
.single-blog-txt > h2 > a:focus {
  color: var(--sky);
}
.single-blog-txt > p {
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #6d6e76 !important;
}
.single-blog-card {
  margin-bottom: 40px;
}
.single-blog-card-img {
  border-radius: 8px;
  margin-bottom: 20px;
}
.single-blog-txt > ul {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.single-blog-txt > ul > li {
  text-align: center;
  text-transform: uppercase;
  color: var(--blue);
  background: #ebf2fe;
  border-radius: 8px;
  padding: 8px;
  margin-right: 8px;
}

/* blog-card-section-content-tab */
.blog-card-section-content-tab {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.blog-section-tab {
  margin: 20px 0 0;
}
.blog-tab-heading {
  margin-bottom: 50px;
}

/* single-blog-wrapper */
.single-blog-wrapper {
  max-width: 700px;
  margin: 30px auto 50px;
}
.subscriber-newsletter {
  padding: 30px;
  background: var(--blue);
  border-radius: 8px;
}
.subscriber-newsletter h2 {
  font-size: 24px !important;
  line-height: 36px !important;
  color: var(--white);
  margin-bottom: 10px !important;
}
.subscriber-newsletter p {
  color: var(--white);
  margin-bottom: 18px;
}
.subscriber-newsletter .subscribe-from {
  margin-bottom: 0px;
}
.newsletter-input {
  border-radius: 8px;
  margin-right: 10px;
  overflow: hidden;
}
.newsletter-input input {
  height: 50px;
  width: 370px;
  border-radius: 2px;
}
.subscriber-newsletter button.btn.btn-large,
.subscriber-newsletter button.btn.btn-large:hover,
.subscriber-newsletter button.btn.btn-large:focus {
  padding: 15px 25px;
  height: 50px;
}

/* single-bolg-content */
.single-bolg-content {
  margin-bottom: 30px;
}
.blog-main-info-txt p.para3 {
  margin-bottom: 30px;
}
.blog-main-info-txt h2 {
  margin-bottom: 10px !important;
}
.blog-main-info-txt h3 {
  color: var(--midText);
  margin-bottom: 10px !important;
}
ul.tag-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.single-tag {
  display: inline-block;
  border: 1px solid var(--borderTwo);
  border-radius: 4px;
  padding: 3px 10px;
  margin: 0 10px 15px 0;
}
.single-tag > h5 {
  color: var(--sky);
}
.blog-share-groupe {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.single-blog-title > h2 {
  margin-bottom: 20px !important;
}
.share-icon-list ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.share-icon-list {
  width: 110px;
  margin-bottom: 20px;
}
