.single-tips {
  margin-bottom: 30px;
}
.single-tips > h2 {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #3ea3f7;
  margin-bottom: 20px;
}
.single-tips > p {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: var(--midText);
}

/* sop-card-list */
.sop-card-list {
  margin: 40px 0 50px;
}
ul.sop-card-list > li {
  flex-direction: column;
}
.single-sop-list .single-sop-img > h3 {
  font-weight: 600;
  font-size: 25px;
  line-height: 29px;
  color: var(--white);
}

.single-sop-body > h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin-top: 12px;
}
