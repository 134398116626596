/* .single-person */
.single-person {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.single-person-img {
  position: relative;
  border-radius: 2px;
  margin-right: 15px;
  overflow: hidden;
}

.small-person-img>img {
  width: 70px;
}

.single-person-img .date-box {
  position: absolute;
  top: 6px;
  left: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #053680;
  width: 26px;
  height: 27px;
  background: var(--white);
  border-radius: 2px;
}

.single-person-body>h3 {
  color: var(--midText);
  margin-bottom: 2px !important;
}

.single-person-body>h6,
.single-person-body>h6.blog-para>a {
  max-width: 220px;
  color: var(--midText);
  margin-bottom: 5px !important;
  transition: all 0.3s linear;
}

.single-person-body>h6.blog-para>a {
  font-size: 14px;
  transition: all 0.3s linear;
}

.single-person-body>h6.blog-para>a:hover,
.single-person-body>h6.blog-para>a:focus {
  color: var(--sky);
  text-transform: inherit;
}

.single-person-body>p {
  display: flex;
  align-items: center;
}

.single-person-body>p>a,
.single-person-body>p>a:hover {
  color: var(--sky);
}

.single-person-body>p>span {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--midText);
  margin-left: 5px;
}

/* connect-counselor-section */
.connect-counselor-section {
  padding: 68px 60px;
  background: url("../images/counselor-bg.png") no-repeat center;
  background-size: cover;
  margin-bottom: 78px;
  border-radius: 8px;
}

.connect-counselor-inner-section>h2 {
  font-weight: 500 !important;
  font-size: 32px;
  line-height: 48px;
  color: var(--white);
  margin-bottom: 20px !important;
  max-width: 550px;
}

/* scholership-footer */
.scholership-tab-heading {
  margin-bottom: 20px;
}

.scholership-tab-heading .btn.btn-tab,
.scholership-tab-heading .btn.btn-tab:hover,
.scholership-tab-heading .btn.btn-tab:focus {
  border: 1px solid #eaeaea !important;
}

.scholership-footer>ul>li {
  display: inline-block;
  margin-right: 5px;
}

/* single-service-list */
.service-card-info {
  padding: 80px 30px 0px;
  margin: 0;
  border-radius: 0;
}

.single-service-img {
  width: 67px;
  height: 67px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.single-service-body>h2 {
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: var(--darkText);
}

.single-service-body>p {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: var(--midText);
}

ul.service-card-list>li {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 285px;
  height: 150px;
  border-radius: 8px;
}

ul.service-card-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1230px;
  margin: 0 auto;
}

.single-service-img {
  margin-right: 10px;
}

ul.service-card-list>li:last-child {
  border: 0;
}

/* hero-section */
.hero-section-content {
  position: relative;
  max-width: 1415px;
  margin: 0 auto;
  overflow: hidden;
}

.hero-section-body {
  max-width: 618px;
}

.about-section-body {
  max-width: 494px;
}

.hero-section-body>h2 {
  margin-bottom: 25px !important;
}

.about-section-body>h2 {
  margin-bottom: 10px !important;
}

.hero-section-body>p {
  color: var(--white);
}

ul.hero-checklist {
  display: flex;
  align-items: center;
}

ul.hero-checklist>li {
  display: flex;
  align-items: center;
  margin-right: 30px;
}

.hero-checklist>li>.icon {
  font-size: 20px;
}

.hero-checklist>li>.txt {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: var(--midText);
  margin-left: 10px;
}

.subscribe-from {
  margin-bottom: 20px;
}

.hero-form-wrapper .btn-hero-subscribe {
  margin-left: 10px;
}

.hero-form-wrapper .btn-hero-subscribe span.icon {
  display: none;
}

.hero-section-img-content {
  position: relative;
}

.hero-section-scholership-img {
  position: absolute;
  top: 55px;
  left: -5px;
  z-index: 1;
}
.hero-section-ielts-img {
  top: 65px;
  left: -45px;
}

.hero-section-person-img {
  text-align: right;
  position: relative;
  margin-right: 50px;
}

.hero-section-shape {
  position: absolute;
  /* bottom: 0; */
  right: 15px;
}

.hero-section-chatting-img {
  position: absolute;
  bottom: 0px;
  right: 45px;
}

/* consultant-section */
.consultant-section {
  background: url("../images/consultant-bg.png") no-repeat center;
  background-size: cover;
  padding: 30px;
  margin-bottom: 20px;
  border-radius: 2px;
}

.consultant-body-txt {
  position: relative;
}

.consultant-body-img>img {
  position: absolute;
  top: -48px;
  right: -60px;
  width: 175px;
}

.consultant-body-txt>h2 {
  color: var(--white);
  margin-bottom: 15px !important;
}

.consultant-body-button {
  display: flex;
  align-items: center;
}

.consultant-body-fees {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.consultant-body-fees-box {
  padding: 20px;
  background: #f7d501;
}

.consultant-body-fees-box {
  max-width: 196px;
}

.consultant-body-fees-box>h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 18px;
  color: var(--pureBlack);
  padding-bottom: 5px;
  border-bottom: 1px solid var(--pureBlack);
  margin-bottom: 5px;
}

.consultant-body-fees-box>p {
  font-size: 12px;
  line-height: 18px;
  color: var(--pureBlack);
}

.consultant-body-fees-img {
  margin-right: 30px;
}

/* res-hero-section-body */
.res-hero-section-body {
  max-width: 100% !important;
}

.res-hero-section-body>h2 {
  display: block !important;
  font-size: 24px;
  line-height: 32px;
  color: var(--black);
}

ul.res-hero-checklist {
  display: block !important;
}

.res-hero-checklist>li>.txt {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: var(--midText);
  margin-left: 10px;
}

.card-info-body .btn-view-all,
.card-info-footer .btn-view-all {
  display: none !important;
}

/* scholership-advertaisement */
.scholership-advertaisement {
  display: none;
  position: relative;
  background: url("../images/scholership-add-bg.png") no-repeat center;
  background-size: cover;
  padding: 52px 12px 50px 45px;
}

.scholership-advertaisement:before {
  position: absolute;
  content: "";
  background: url("../images/blue-overlay-two.png") no-repeat center;
  background-size: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.scholership-advertaisement>ul {
  position: relative;
  display: flex;
  align-items: flex-start;
}

.scholership-advertaisement-content {
  max-width: 185px;
}

.scholership-advertaisement-content>h4 {
  font-weight: 700;
  font-size: 18px;
  line-height: 14px;
  color: var(--white);
  padding-bottom: 2px;
  border-bottom: 2px solid #fad02f;
  margin-bottom: 12px;
}

.scholership-advertaisement-content>h2 {
  font-weight: 700;
  font-size: 64px;
  line-height: 71px;
  color: #fad02f;
  margin-bottom: 10px;
}

.scholership-advertaisement-content>h3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 27px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: var(--white);
  margin-bottom: 30px;
}

.btn.btn-apply-add,
.btn.btn-apply-add:hover,
.btn.btn-apply-add:focus {
  background: #fad02f !important;
  border: 1px solid #fad02f !important;
  border-radius: 2px;
  padding: 8px 9px !important;
  font-weight: 700;
  font-size: 18px !important;
  color: #000000 !important;
}

.scholership-advertaisement>ul>li img {
  width: 94px;
}

/* contact-form */
.contact-form .form-container {
  margin-bottom: 10px;
}

.contact-form .input-field-main label {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--darkText);
  margin-bottom: 5px;
}

/* visa process */
.visa-process-hero-section-body {
  max-width: 100%;
}

.visa-process-step>.card-info-heading {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--border);
}

ul.visa-process-step-list>li {
  flex-direction: column;
  min-width: 33%;
  margin-bottom: 20px;
}

.single-visa-process-step-body {
  max-width: 290px;
  margin: 0 auto;
}

.single-visa-process-step-img {
  width: 59px;
  height: 59px;
  margin-right: 0;
  font-weight: 600;
  font-size: 25px;
  line-height: 29px;
  color: var(--white);
  margin: 0 auto 12px;
}

.single-visa-process-step-body>h3 {
  color: var(--darkText);
  text-align: center;
  padding-bottom: 30px;
  border-bottom: 1px solid var(--borderSeven);
  margin-bottom: 20px !important;
}

.single-visa-process-step-body p {
  color: var(--dark-text-three);
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
}

ul.hero-form-list>li {
  display: inline-block;
  width: 220px;
  margin-right: 17px;
}

.hero-form-list>li>.btn.btn-large,
.hero-form-list>li>.btn.btn-large:hover,
.hero-form-list>li>.btn.btn-large:focus {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  padding: 11px 20px;
  position: relative;
  top: -3px;
}

/* single-visa-processing-card-section */
.visa-process-step-content {
  margin-top: 30px;
}

.single-visa-process-step {
  border-radius: 8px;
  padding: 30px;
  min-height: 500px;
}

.single-visa-processing-card-section {
  padding: 0px 0px 10px;
  max-width: 718px;
  margin: 0 auto;
}

.single-visa-processing-card-section>.card-info-heading {
  padding: 18px 30px 17px;
  background: #dcdfe9;
  border-radius: 8px;
}

.single-visa-processing-card-section>.card-info-body {
  padding: 0px 30px 0px;
}

.single-visa-check-list {
  margin: 30px 0 0;
}

.single-visa-check-list ul {
  margin: 20px 0 0;
}

.single-visa-check-list ul>li {
  font-weight: 400;
  margin-bottom: 5px;
}

.single-visa-check-list ul>li.col-xl-4 {
  padding-left: 0;
}

.single-visa-process-title-section {
  margin: 40px 0 0;
  border-top: 1px solid rgba(122, 122, 122, 0.3);
  padding: 40px 0 0;
  max-width: 800px;
}

.single-visa-process-title-section h2 {
  color: var(--midText);
}

.single-visa-process-title-section .btn.btn-large,
.single-visa-process-title-section .btn.btn-large:hover,
.single-visa-process-title-section .btn.btn-large:focus {
  padding: 0;
  width: 188px;
  height: 50px;
}

/* scholars-list-section */
.scholars-list-section {
  padding: 80px 0;
  margin-bottom: 80px;
  overflow: hidden;
}

.scholars-list-section-content {
  padding: 0 15px;
}

.scholars-list {
  margin: 40px 0 0;
}

.scholars-list>ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.scholars-list>ul li.single-profile-info {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column;
}

.profile-info-img {
  position: relative;
  border-radius: 8px;
}

.scholarship-info-img {
  border: 1px solid var(--borderOne);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 213px;
  background: var(--white);
}

.profile-info-img>img {
  position: relative;
  z-index: 1;
}

.profile-info-img-radius>img {
  width: 115px;
  border-radius: 0px;
}

.profile-info-img-colorbox {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 176px;
  height: 163px;
  border-radius: 14px;
}

.profile-info-img-shape {
  position: absolute;
  top: 10px;
  left: -45px;
}

.single-profile-info .profile-info-txt {
  margin: 24px 0 20px;
}

.profile-info-txt {
  text-align: center;
}

.profile-info-txt>p {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 4px;
}

.single-topics-info {
  position: relative;
  overflow: hidden;
  transition: 0.3s linear;
}

.single-topics-info-backpart {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  display: flex;
  padding: 65px 40px 20px;
  border-radius: 8px;
  transition: 0.3s linear;
}

.single-topics-info:hover .single-topics-info-backpart {
  opacity: 1;
  visibility: visible;
  z-index: 1;
}

.single-topics-info-backpart-txt {
  text-align: center;
}

.single-topics-info-backpart-txt>h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: var(--white);
  margin-bottom: 10px;
}

.single-topics-info-backpart-txt>p {
  font-weight: 400;
  font-size: 14px;
  color: var(--white);
}

.scholars-list-footer {
  text-align: center;
  margin-top: 30px;
}

.scholars-list-footer button.btn.btn-default,
.scholars-list-footer button.btn.btn-default:hover,
.scholars-list-footer button.btn.btn-default:focus {
  background: var(--darkOrange) !important;
  border: 1px solid var(--darkOrange) !important;
  color: var(--white) !important;
  padding: 17px 36px;
  font-weight: 700;
  border-radius: 2px !important;
}

.single-topics-info-backpart-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

/* timeline-scholership-section */
.timeline-scholership-section {
  margin: 0 0 70px;
}

.timeline-scholership-section .card-sm-body {
  height: 260px;
}

.timeline-scholership-graph {
  padding: 30px 40px 45px;
  box-shadow: 0px 34px 24px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.timeline-scholership-txt {
  max-width: 550px;
}

.timeline-scholership-txt.section-text>h2.title {
  margin-bottom: 30px;
}

.timeline-scholership-txt.section-text>h3.sub-title {
  margin-bottom: 20px;
}

.timeline-scholership-txt.section-text>p.para {
  margin-bottom: 30px;
}

/* hm-about-section */
.hm-about-section {
  padding: 50px 0 15px;
}

.sop-about-section {
  padding: 80px 0 80px;
}

.hm-about-para {
  margin-bottom: 20px;
}

/* expert-professor-section */
.expert-professor-section {
  margin: 0 0 60px;
}

.expert-professor-content .section-title {
  max-width: 550px;
  margin: 0 auto;
}

.expert-professor-list {
  margin: 30px 0 0;
}

.expert-professor-list .profile-info-txt {
  text-align: left;
}

.expert-professor-list .profile-info-img>img {
  width: 100%;
  border-radius: 8px;
}

/* latest-scholership section */
.latest-scholership-section {
  padding: 45px 0 55px;
}

.latest-scholership-list {
  margin: 30px 0 0;
}

.latest-scholership-list .profile-info-img-radius>img {
  width: 100%;
}

.latest-scholership-list .profile-info-txt .h4 {
  font-size: 20px;
  line-height: 25px;
}

.single-topics-info button.btn.btn-large,
.single-topics-info button.btn.btn-large:hover,
.single-topics-info button.btn.btn-large:focus {
  width: 205px;
  height: 50px;
  border-radius: 70px !important;
  padding: 0;
  font-weight: 500;
}

/* hm-feature-section */
.hm-feature-section {
  padding: 70px 0 80px;
}

.hm-feature-content .section-title {
  max-width: 520px;
  margin: 0 auto;
}

.hm-feature-list {
  margin: 50px 0 0;
}

/* feature-info */
.feature-profile-info {
  padding: 50px 0 30px;
}

.feature-profile-info.even {
  padding: 50px 0 0 50px;
}

.feature-profile-info.odd {
  border-right: 1px solid var(--border);
  border-bottom: 1px solid var(--border);
}

.feature-profile-info.even {
  border-bottom: 1px solid var(--border);
}

.feature-profile-info.padTopRemove {
  padding-top: 0px;
}

.feature-profile-info.borderRemove {
  border-bottom: 0;
}

.feature-info-img>img {
  width: 275px;
}

.feature-info-text {
  max-width: 550px;
  text-align: left;
}

.feature-info-text h2 {
  font-weight: 700;
  font-size: 28px;
  line-height: 39px;
  color: var(--darkText);
  margin-bottom: 20px;
}

.feature-info-text h3 {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 16px;
}

.feature-info-text p {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: var(--btn-dark-text);
  margin-bottom: 30px;
}

/* hm-student-review-section */
.hm-student-review-section {
  margin: 70px 0 0;
}

.hm-student-review-inner {
  position: relative;
  margin: 40px 0 0;
  border-radius: 8px;
  overflow: hidden;
}

.hm-student-review-inner-img {
  position: relative;
}

.hm-student-review-inner-img>img {
  width: 100%;
}

.hm-student-review-inner-img-shape {
  position: absolute;
  top: 0;
  left: -25px;
}

.hm-student-review-inner-shape {
  position: absolute;
  bottom: 10px;
  left: 45%;
}

.hm-student-review-inner-txt-content {
  padding: 70px 80px 60px;
}

.single-review {
  max-width: 455px;
  padding-bottom: 45px;
}

.single-review-img {
  margin-bottom: 47px;
}

.single-review-comment>h2 {
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: var(--darkText);
  margin-bottom: 24px;
}

.single-review-comment>p {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: var(--midText);
}

.single-review-comment-author {
  margin: 30px 0 45px;
}

.single-review-comment-author>h3 {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--darkText);
  text-transform: uppercase;
}

.single-review-comment-author>p {
  font-weight: 400;
  font-size: 13px;
  line-height: 28px;
  color: var(--midText);
}

.hm-student-review-quotation {
  display: none;
}

/* top-scholership-institute-section */
.top-scholership-institute-section {
  padding: 70px 0 0;
  overflow: hidden;
}

.top-scholership-institute-list {
  position: relative;
  margin: 24px 0 0;
}

.img-100>img {
  width: 100%;
}

.text-default {
  text-align: left;
}

.profile-info-txt>h3.button-type {
  padding: 7px 10px;
  background: var(--bgOne);
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--darkText);
  margin: 8px 0 4px;
}

.more-info-link>a,
.more-info-link>a:active,
.more-info-link>a:focus {
  text-decoration: underline;
  color: var(--orange);
  font-size: 14px;
  font-weight: 500;
}

.top-scholership-institute-list-shape-right {
  position: absolute;
  top: 0px;
  right: -10%;
  z-index: -1;
}

.top-scholership-institute-list-shape-left {
  position: absolute;
  top: -50px;
  left: 10px;
  z-index: -1;
}

.top-scholership-institute-list .single-topics-info {
  position: relative;
  margin: 15px;
  z-index: 1;
}

.single-topics-info-link>a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  cursor: pointer;
}

.default-slick-arrow .slick-prev,
.default-slick-arrow .slick-next {
  top: 35%;
  width: 68px;
  height: 68px;
  background: var(--white);
  border-radius: 100%;
  box-shadow: 0px 14px 34px rgb(0 0 0 / 10%);
}

.default-slick-arrow .slick-next {
  right: -85px;
}

.default-slick-arrow .slick-prev {
  left: -85px;
}

.default-slick-arrow .slick-prev:before,
.default-slick-arrow .slick-next:before {
  font-size: 24px;
  color: #757575;
}

.default-slick-arrow .slick-prev:before {
  content: url("../images/icon/leftArrow.png");
}

.default-slick-arrow .slick-next:before {
  content: url("../images/icon/rightArrow.png");
}

/* hero-section */
button.btn.hm-hero-btn>span {
  margin: 0 !important;
}

button.btn.hm-hero-btn>span.arrow-icon {
  margin: 10px !important;
}

.hm-hero-btn .search-icon {
  display: none;
}

button.btn.btn-large.hm-hero-btn,
button.btn.btn-large.hm-hero-btn:hover,
button.btn.btn-large.hm-hero-btn:focus {
  padding: 20px 20px;
}

.hero-checklist .icon.one,
.hero-checklist .icon.two,
.hero-checklist .icon.three {
  color: #a2b2c5;
}

/* IELTS */
.ielts-card-booking-card {
  padding: 65px 82px;
  background: var(--blue);
  border-radius: 6px;
  text-align: center;
}

.ielts-card-booking-card>h2 {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: var(--white);
  max-width: 287px;
  margin: 0px auto 30px;
}