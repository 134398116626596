/* sidebar */
.sidebar-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-less);
}
.sidebar-overlay.overlay-none {
  z-index: 9;
}
.logo-img.sidebar-logo img {
  width: 130px;
}
.sidebar .logo-wrapper {
  cursor: pointer;
  width: auto;
}
.sidebar .logo-inner-wrapper {
  display: flex;
  align-items: center;
  padding: 30px 0px;
  width: 100%;
}

.sidebar-menu-icon {
  display: none;
}

@media (max-width: 767px) {
  .logo i.fa-bars {
    display: block;
  }
}

/* side-menu-wrapper */
.side-menu-wrapper {
  margin: 40px 0 0;
  height: 450px;
  overflow-y: scroll;
  position: fixed;
  width: 275px;
  top: 80px;
}

/* filter-menu */
.filter-menu {
  margin: 24px 0;
}
.filter-menu > ul > li {
  margin-bottom: 20px;
}
.filter-menu .input-field-main label {
  text-transform: uppercase;
}
