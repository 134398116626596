.footer-section {
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.subscribe-from {
  display: flex;
}
.fotter-left-shape,
.fotter-right-shape {
  position: absolute;
  bottom: 0;
  left: -100px;
}
.fotter-right-shape {
  left: auto;
  right: 0;
}
/* .footer-top */
.footer-top {
  position: relative;
  z-index: 1;
  padding: 50px 0;
}
.footer-logo-content {
  margin-bottom: 40px;
}
.footer-social-network > ul > li {
  display: inline-block;
  margin-right: 20px;
}
.footer-social-network > ul > li:last-child {
  margin-right: 0px;
}
.footer-social-network > ul > li > a {
  color: var(--darkText);
}
.footer-menu h3.footer-menu-title {
  margin-bottom: 20px !important;
}
h3.footer-menu-title {
  font-weight: 700 !important;
}
.footer-menu-left ul > li > a.h5:hover,
.footer-menu-left ul > li > a:hover {
  text-transform: inherit;
  color: var(--sky);
}
.fotter-divider {
  height: 1px;
  border-bottom: 1px solid #f0f0f0;
}
/* .footer-bottom */
.footer-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0 65px;
}
.footer-bottom p {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #78787d;
}
.footer-bottom ul li {
  display: inline-block;
}
.footer-bottom ul li a,
.footer-bottom ul li a:hover,
.footer-bottom ul li a:focus {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #78787d;
  margin-right: 25px;
}
.footer-form .default-button {
  margin-left: 10px;
}

/* responsive */
.res-footer-inner {
  width: 100%;
  background: var(--white);
  margin-bottom: 28px;
}
.res-footer-menu {
  text-align: center;
  padding: 60px 0 20px;
  margin: 0 auto;
}
.res-footer-menu-img {
  margin-bottom: 20px;
}
ul.res-footer-menu-list {
  text-align: center;
}
ul.footer-social-list {
  padding: 20px 0;
  text-align: center;
  border-top: 1px solid #d9d9d9;
}
ul.footer-social-list > li,
ul.res-footer-menu-list > li {
  display: inline-block;
  margin-right: 20px;
}
ul.res-footer-menu-list > li {
  display: block;
  margin-right: 0;
  margin-bottom: 20px;
}
ul.res-footer-menu-list > li > a {
  line-height: 28px;
  font-weight: 400;
  text-decoration: underline;
  color: #4d4d4d;
}
.footer-web-duration {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  padding: 20px 0;
  color: #6a6a6a;
  background: #f2f3f6;
  text-align: center;
}

/* sliding-footer */
.sliding-footer {
  z-index: 9999;
  width: 100%;
  position: fixed;
  bottom: -85px;
  display: block;
  padding: 10px 0;
  background: #e4ecff;
  transition: bottom 0.3s;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.3);
}
.sliding-footer.active {
  bottom: 0px;
}
.sliding-site-area {
  margin: 0 auto !important;
  width: 95%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.sliding-footer-content {
  display: flex;
  align-items: center;
}
.sliding-footer-content > a,
.sliding-footer-content > a:hover,
.sliding-footer-content > a:focus {
  margin-right: 20px;
}
.slider-content-right {
  display: flex;
  align-items: center;
}
.sliding-footer .sliding-footer-content .call {
  padding-right: 15px;
}
.sliding-footer .sliding-footer-content .call,
.sliding-footer .sliding-footer-content .sliding-live-chat {
  display: inline-flex;
  align-items: center;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  color: var(--black);
}
.sliding-footer .sliding-footer-content .call img,
.sliding-footer .sliding-footer-content .class-start-date img {
  margin-right: 5px;
}
.divider {
  color: #330066;
}
.sliding-footer .sliding-footer-content .sliding-live-chat {
  margin-left: 15px;
}
