.notication-wrapper {
  margin: 50px auto 80px;
}
.notication-head {
  display: flex;
  align-items: flex-start;
}
.goback {
  display: flex;
  align-items: center;
  cursor: pointer;
  max-width: 95px;
}
.goback > img {
  margin-right: 11px;
}
.goback > h3 {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--midText);
}
.notification-list {
  margin: 30px 0 0;
}
.notification-list > ul > li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background: var(--white);
  box-shadow: inset 0px -1px 0px var(--border);
  border-radius: 2px;
  margin: 0 0 1px;
}
.notification-list-body {
  display: flex;
  align-items: center;
}
.notification-list-body > img {
  width: 51px;
  height: 51px;
  border-radius: 50%;
  margin-right: 15px;
}
.notification-list-txt > h2 > a {
  font-weight: 500;
  font-size: 13px;
  line-height: 23px;
  color: var(--darkText);
  margin-bottom: 5px;
}
.notification-list-txt > p {
  font-weight: 400;
  font-size: 13px;
  line-height: 23px;
  color: #7b7b7b;
}
