.faq-hero-section {
  margin-bottom: 30px;
}
.visa-hero-section {
  margin-bottom: 80px;
}
.hero-section-person-img.faq-section-person-img {
  height: 215px;
}
.hero-section-person-img.visa-hero-img {
  height: 370px;
}
.faq-hero-section .hero-section-shape {
  bottom: -50px;
  right: 75px;
  width: 375px;
}
.visa-hero-section .hero-section-shape {
  bottom: -10px;
  right: 15px;
  width: 540px;
}
/* faq-card-section */
.faq-card-section {
  margin: 80px 0;
}
.ielts-card-section {
  padding: 80px 0;
}

/* faq-question-card-section-content */
.faq-question-card-section {
  position: relative;
  margin: 0 0 80px;
  padding: 60px 0;
}
.faq-question-card-section-content {
  max-width: 950px;
  margin: 0 auto;
}
.faq-ask-form {
  background: var(--white) !important;
  padding: 30px 20px;
  border-radius: 8px;
}
.faq-ask-form-img {
  position: absolute;
  bottom: -145px;
}
.faq-ask-form-img > img {
  height: 340px;
}

.visa-procee-hero-section {
  padding: 80px 40px;
}
.visa-process-hero-section-body button.btn.btn-medium,
.visa-process-hero-section-body button.btn.btn-medium:hover,
.visa-process-hero-section-body button.btn.btn-medium:focus {
  height: 50px;
  font-weight: 500 !important;
  position: relative;
  top: -2px;
}
.faq-hero-section-body .subscribe-from {
  margin-bottom: 0px;
}
.apply-section {
  position: relative;
  background: url("../images/institute3.png") no-repeat center;
  background-size: cover;
  padding: 64px 68px;
  overflow: hidden;
}
.apply-section:before {
  position: absolute;
  content: "";
  background: url("../images/blue-gradient.png") no-repeat center;
  background-size: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.apply-section-txt {
  position: relative;
  max-width: 450px;
}
.apply-section-txt > h2 {
  color: var(--white);
  margin-bottom: 30px !important;
}
.faq-list > li {
  padding-bottom: 20px;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 24px;
}
.faq-list > li:last-child {
  padding-bottom: 0px;
  border-bottom: 1px solid transparent;
}
.faq-list > li > h2 {
  color: var(--blueTwo);
  margin-bottom: 8px !important;
}
.faq-list > li > p {
  color: var(--primayColor);
}
.faq-list > li > p > a {
  color: var(--red);
  text-transform: initial;
}

.visa-process-hero-section-body .hero-form-list > li > .input-field-main label {
  font-weight: 600;
  color: var(--black);
  margin-bottom: 10px;
}

/* ielts-section */
.hm-feature-content.ielts-feature-content .section-title {
  max-width: 850px;
  margin: 0 auto;
}
.ielts-feature-img {
  margin-top: 40px;
  text-align: center;
}
