.auto-sugession-wrapper {
  position: relative;
}

.auto-sugession-bar {
  position: absolute;
  width: 98%;
  height: 150px;
  background: white;
  overflow-y: auto;
  top: 0;
  margin-top: 57px;
  box-shadow: 0px 24px 90px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 12px 12px;
}

.result-div {
  border: none;
  width: 100%;
  background: white;
  padding: 5px 20px;
  border-bottom: 1px solid var(--border);
  cursor: pointer;
  display: flex;
  text-align: left;
  justify-content: space-between;
}

.result-div-hover {
  background: whitesmoke;
}

.result-div p {
  font-size: 13px;
}
.result-div span {
  font-size: 12px;
  color: gray;
}

.result-div:hover {
  background: whitesmoke;
}
