@media screen and (max-width: 1799px) {
  .faq-hero-section-body .default-search {
    width: 530px;
  }

  ul.visa-process-step-list > li {
    min-width: 33%;
  }

  .scholership-footer > ul > li {
    margin-bottom: 5px;
  }

  .timeline-row .MuiBox-root {
    width: 230px;
  }

  .timeline-row.timeline-section .MuiBox-root {
    width: 100%;
  }
}

@media screen and (max-width: 1699px) {
  /* timeline */
  .timeline-row .MuiBox-root {
    width: 200px;
  }

  /* footer */
  ul.visa-process-step-list > li {
    min-width: 33%;
  }
}

@media screen and (max-width: 1599px) {
  /* latest scholership */
  .latest-scholership-section {
    padding: 45px 30px 55px;
  }

  /* footer */
  .default-input input {
    width: 238px;
  }

  /* service */
  ul.visa-process-step-list > li {
    min-width: 33%;
  }

  /* hm-feature-section */
  .hm-feature-section {
    padding: 30px 30px;
  }

  .hm-feature-section .single-profile-info .profile-info-txt {
    margin: 20px 0px 0px;
  }

  .hm-feature-section .single-profile-info .profile-info-txt {
    max-width: 415px;
  }

  .hm-feature-section .profile-info-img {
    text-align: center;
    max-width: 415px;
  }

  /* consultant */
  .consultant-body-txt > h2 {
    max-width: 420px;
  }

  /* timeline */
  .timeline-row .MuiBox-root {
    width: 160px;
  }

  /* blog */
}

@media screen and (max-width: 1499px) {
  /* timeline */
  .timeline-row .MuiBox-root {
    width: 130px;
  }
}

@media screen and (max-width: 1399px) {
  /* typography */
  .h1 {
    font-size: 24px;
    line-height: 32px;
  }

  .h2 {
    font-size: 20px;
    line-height: 28px;
  }

  .h3,
  .para1 {
    font-size: 16px;
    line-height: 24px;
  }

  .h4,
  .para2,
  .para3,
  a.link-big,
  a.link-big:hover,
  a.link-big:focus {
    font-size: 14px;
    line-height: 22px;
  }

  .h5,
  .para4 {
    font-size: 12px;
    line-height: 18px;
  }

  /* top-navigation */
  .app .main .sidebar {
    top: 138px;
  }

  /* hero-section */
  .hero-section-content {
    padding: 0 15px;
  }

  .hero-section-body {
    z-index: 1;
    position: relative;
    max-width: 380px;
  }

  .visa-process-hero-section-body {
    max-width: 100%;
  }

  .hm-hero-btn .text,
  .hm-hero-btn .arrow-icon {
    display: none;
  }

  .hm-hero-btn .search-icon {
    display: block;
  }

  ul.hero-checklist > li {
    margin-right: 12px;
    padding-right: 8px;
    border-right: 1px solid var(--border);
  }

  .hero-checklist > li > .txt {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: var(--midText);
    margin-left: 10px;
  }

  ul.hero-checklist > li:last-child {
    margin-right: 0;
    padding-right: 0;
    border-right: 1px solid var(--color-less);
  }

  .default-search.hero-search-form {
    margin: 0;
    border-radius: 0px !important;
    border-radius: 2px !important;
  }

  button.btn.hm-hero-btn {
    border-radius: 0px !important;
    border-top-right-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
  }

  /* timeline */
  .timeline-row .single-cell,
  .timeline-row .title {
    font-size: 11px;
  }

  .single-person {
    align-items: baseline;
    flex-direction: column;
  }

  .whatspp-body > h2,
  .whatspp-body > p {
    font-size: 13px;
    line-height: 18px;
  }

  .whatsapp-half-circle > img {
    width: 45px;
  }

  .whatspp-body > p > img {
    margin-right: 0px;
  }

  /* top scholership */
  .single-profile-info .profile-info-txt {
    max-width: 165px;
    margin: 20px auto 20px;
    text-align: center;
  }

  .profile-info-txt > h3.button-type {
    font-size: 14px;
  }

  .default-slick-arrow .slick-prev,
  .default-slick-arrow .slick-next {
    top: 30%;
    width: 60px;
    height: 60px;
    z-index: 1;
  }

  .default-slick-arrow .slick-next {
    right: 0px;
  }

  .default-slick-arrow .slick-prev {
    left: 0px;
  }

  .default-slick-arrow .slick-prev:before,
  .default-slick-arrow .slick-next:before {
    font-size: 12x;
  }

  /* latest-scholership-section */
  .latest-scholership-section {
    padding: 10px 30px 30px;
  }

  .single-topics-info-backpart {
    padding: 20px 15px;
  }

  /* hm-about-section */
  .hm-about-section {
    padding: 30px 30px;
  }

  /* .hm-student-review-section  */
  .hm-student-review-section {
    margin: 30px 0 0;
  }

  .hm-student-review-inner {
    margin: 20px 0 0;
  }

  .hm-student-review-section.about-hm-review {
    margin: 0;
    padding: 30px 0;
  }

  .about-hm-review .hm-student-review-inner {
    margin: 0 30px;
  }

  /* .scholars-list-section */
  .scholars-list-section {
    padding: 30px 0;
    margin-bottom: 30px;
  }

  .scholars-list {
    margin: 30px 0 0;
  }

  /* timeline-scholership */
  .timeline-scholership-txt.section-text > h2.title {
    margin-bottom: 15px;
  }

  /* .expert-professor-section */
  .expert-professor-section {
    padding: 0 30px 0;
  }

  /* .timeline-scholership-section */
  .timeline-scholership-section {
    padding: 0 30px;
  }

  .timeline-row.timeline-section .MuiBox-root {
    width: 75%;
  }

  /* consultant */
  .connect-counselor-section {
    margin: 0 15px 78px;
  }

  .consultant-body-img,
  .consultant-body-fees-img {
    display: none;
  }

  /* scholarship-portal-section */
  .scholarship-portal-section {
    margin: 0px 0 5px;
  }

  .scholarship-portal-section-content {
    padding: 30px 30px 0px;
  }

  /* .core-points-info */
  .core-points-info {
    padding: 30px 30px 0px;
  }

  .core-points-info-body {
    padding: 20px 0 0;
  }

  /* .team-section */
  .team-section {
    padding: 30px;
  }

  .team-section-content-body {
    padding: 20px 30px 0px;
  }

  /* letter-section */
  .letter-section {
    margin: 30px 0 5px;
  }

  .letter-section-content {
    padding: 0 30px;
  }

  /* faq */
  .faq-card-section {
    margin: 30px 15px;
  }

  .ielts-card-section {
    margin: 30px 0px;
    padding: 30px 15px;
  }

  .hero-section-body.faq-hero-section-body {
    margin: 0 0;
  }

  .faq-hero-section-body > h2 {
    display: block;
  }

  .faq-question-card-section {
    margin: 0 0 30px;
    padding: 30px 0;
  }

  .faq-ask-form-img {
    bottom: -113px;
  }

  .faq-ask-form-img > img {
    height: 250px;
  }

  /* serch */
  .single-search-item-footer > ul > li:first-child {
    margin-bottom: 10px;
  }

  /* visa process step */
  ul.hero-form-list > li {
    width: 195px;
    margin-right: 10px;
  }

  .single-visa-process-step-body > h2 {
    max-width: 200px;
  }

  /* my-list */
  .my-list-section {
    padding: 0 15px;
  }

  /* search */
  .search-section {
    padding: 0 15px;
  }

  /* footer */
  .footer-top {
    padding: 50px 15px;
  }

  .res-footer-inner {
    margin-bottom: 0px;
  }

  .layout-scroll {
    height: calc(100vh - 15vh);
  }

  /* visa-processing */
  .visa-hero-section {
    margin-bottom: 30px;
  }

  .visa-process-step-content {
    margin-top: 30px;
    padding: 0 10px 0 10px;
  }

  /* blog-section */
  .blog-card-section {
    margin: 0px 30px 0px;
  }

  .single-blog-wrapper {
    margin: 30px auto;
  }

  /* notification */
  .notication-wrapper {
    margin: 30px 0 30px;
  }

  /* sop-card-list */
  .sop-card-list {
    margin: 30px 0 0px;
  }

  /* search-section-body */
  .single-search-item {
    height: auto;
  }
}

@media screen and (max-width: 1299px) {
  /* topnavigation */
  .top-user {
    margin-left: 10px;
  }

  /* card right */
  .single-person {
    align-items: left;
    flex-direction: column;
  }

  /* hero-section */
  .hero-section-scholership-img {
    display: none;
  }

  .hero-section-content {
    padding: 0 30px;
  }

  .visa-hero-content {
    padding: 0 15px;
  }

  /* visa-process-step */
  ul.hero-form-list > li {
    width: 175px;
  }
}

@media screen and (max-width: 1199px) {
  .res-app-width {
    padding: 0 15px;
  }

  /* topnavigation */
  .top-navigation-wrapper {
    padding: 0;
  }

  .top-navigation {
    padding: 0;
  }

  .header-top {
    padding: 20px 15px 20px;
    border-bottom: 1px solid var(--border) !important;
  }

  .header-bottom {
    padding: 0px 0;
  }

  .main-overlay {
    opacity: 1;
    visibility: visible;
  }

  .top-logo-wrapper {
    display: flex;
  }

  .blank-left-side {
    margin-left: 0;
  }

  .width-15 {
    width: 245px;
  }

  .default-search.desktop-top-search,
  .res-seclect-search {
    display: none;
  }

  .top-menu {
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }

  .top-menu-right {
    display: none;
    flex-direction: column;
    align-items: flex-start;
  }

  .top-user {
    display: inline-block;
    margin-left: 0px;
    margin-bottom: 10px;
  }

  .res-top-user {
    margin-bottom: 0px;
  }
  .notification-bell.res-top-user {
    margin-right: 10px;
  }

  .responsive-filter-serch > .subscribe-from {
    margin: 0;
  }

  .responsive-filter-serch {
    align-items: center;
    justify-content: center;
    top: 67px;
    background: url("../images/welcome-banner/08.png") no-repeat center;
    background-size: cover;
  }

  /* search-item */
  .contact-form .input-sm input {
    height: 38px;
  }

  /* service */
  .service-card-info {
    padding: 30px;
  }

  ul.service-card-list > li,
  ul.service-card-list > li:last-child {
    min-width: 49%;
    padding: 10px;
    margin-bottom: 20px;
  }

  /* top scholership */
  .profile-info-txt > h3.button-type {
    background: var(--white);
  }

  .top-scholership-institute-list .single-topics-info {
    margin: 0 15px;
  }

  .single-profile-info .profile-info-txt {
    margin: 20px auto 0px;
  }

  /* latest scholership */
  .single-topics-info button.btn.btn-large,
  .single-topics-info button.btn.btn-large:hover,
  .single-topics-info button.btn.btn-large:focus {
    width: 150px;
    height: 40px;
  }

  /* scholars-list-section  */
  .scholars-list-section {
    display: none;
  }

  /* tips-list-section  */
  .scholars-list-section.tips-list-section {
    display: block;
  }

  /* res-contact-form */
  .res-contact-form {
    padding: 30px 30px 30px 30px;
  }

  .res-contact-form .card-info-heading {
    justify-content: center;
  }

  .res-contact-form .login-input input,
  .login-input textarea {
    height: 46px;
    border-radius: 4px;
  }

  .res-contact-form .btn-view-all,
  .res-contact-form .btn-view-all {
    display: block !important;
  }

  .res-contact-form button.btn.btn-orange,
  .res-contact-form button.btn.btn-orange:hover,
  .res-contact-form button.btn.btn-orange:focus {
    font-size: 15px !important;
    font-weight: 600;
    line-height: 18px !important;
    border-radius: 4px !important;
  }

  /* expert-professor-section */
  .expert-professor-section {
    display: none;
  }

  /* footer */
  .footer-section {
    padding: 0;
    flex-direction: column;
  }

  /* search section */
  .search-section {
    margin: 0;
  }

  .single-search-item-footer > ul > li:first-child {
    margin-bottom: 0px;
  }

  .single-search-item-footer > ul > li:first-child a {
    margin: 10px 0;
  }

  /* notification */
  .notication-wrapper .col-12 {
    padding: 0;
  }

  /* blog */
  .single-blog-card {
    margin-bottom: 20px;
  }

  .blog-main-info-img > img {
    height: auto;
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .section-title > h2 {
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
  }

  /* layout */
  .res-p-0 {
    padding: 0;
  }

  .card-info {
    padding: 22px 14px 10px;
    box-shadow: none;
    border-radius: 0px;
    margin-bottom: 0px;
  }

  .card-info-body .btn-view-all,
  .card-info-footer .btn-view-all {
    display: block !important;
  }

  .card-load-more {
    display: none;
  }

  .card-info-footer .btn.btn-view-all {
    border: 1px solid var(--border) !important;
    text-decoration: underline;
  }

  .single-circle-card-body {
    max-width: 100%;
    margin: 10px auto 0;
  }

  .single-card-list {
    margin-bottom: 10px;
  }

  /* carousel */
  .carousel-control-next,
  .carousel-control-prev {
    height: 20px;
    width: 20px;
    font-size: 10px;
  }

  .carousel-control-next:hover,
  .carousel-control-prev:hover,
  .carousel-control-next:focus,
  .carousel-control-prev:focus {
    font-size: 10px;
  }

  .carousel-control-prev {
    left: 45%;
  }

  .carousel-control-next {
    left: 50%;
  }

  /* topnavigation */
  .top-navigation {
    flex-wrap: wrap;
  }

  /* .hero-section */
  .hero-section {
    padding: 50px 0 40px;
  }

  .hero-section-img-content {
    display: none;
  }

  .about-hm-review .hm-student-review-inner {
    margin: 0;
    background: var(--bgOne);
  }

  /* service */

  /* top scholership */
  /* .default-slick-arrow .slick-prev,
  .default-slick-arrow .slick-next {
    top: 90%;
  } */
  .top-scholership-institute-section {
    padding: 20px 15px;
    background: var(--bgOne);
  }

  .top-scholership-institute-list {
    margin: 20px 0 0;
  }

  .timeline-row.timeline-section .MuiBox-root {
    width: 100%;
  }

  .default-slick-arrow .slick-prev:before {
    content: url("../images/icon/smLeftArrow.png");
  }

  .default-slick-arrow .slick-next:before {
    content: url("../images/icon/smRightArrow.png");
  }

  /* latest scholership */
  .latest-scholership-section {
    padding: 25px 30px 10px;
  }

  .single-topics-info {
    margin-bottom: 20px;
  }

  /* hm-about-section */
  .hm-about-content-img {
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
  }

  .timeline-scholership-txt {
    max-width: 550px;
    margin: 0 auto;
    text-align: center;
  }

  .sop-about-section .timeline-scholership-txt,
  .sop-about-section .hm-about-content-img {
    max-width: 550px;
    margin: 20px 0;
    text-align: left;
  }

  /* .hm-about-section */
  .hm-about-section {
    display: none;
  }

  .sop-about-section {
    display: block;
  }

  /* .hm-student-review-section  */
  .hm-student-review-section {
    margin: 0px 0 0;
    padding: 30px 30px 0;
    background: var(--bgOne);
  }

  .hm-student-review-inner-img-shape {
    display: none;
  }

  .hm-student-review-quotation {
    display: block;
    text-align: center;
    margin-bottom: 20px;
  }

  .hm-student-review-inner-img {
    text-align: center;
  }

  .hm-student-review-inner-img > img {
    width: auto;
    max-width: 350px;
    text-align: center;
    border-radius: 8px;
  }

  .single-review-img {
    display: none;
  }

  .single-review {
    max-width: 390px;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 20px;
  }

  .hm-student-review-inner-txt-content {
    padding: 20px 30px 30px;
    position: relative;
  }

  .single-review-comment > h2 {
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 10px;
  }

  .single-review-comment > p {
    font-size: 18px;
    line-height: 25px;
    font-style: italic;
  }

  .single-review-comment-author {
    margin: 20px 0 20px;
  }

  .single-review-comment-author > h3 {
    color: #053680 !important;
  }

  .hm-student-review-inner-shape {
    display: none;
  }

  /* application process */
  .single-card-list.single-circle-card-list {
    padding: 15px 13px;
  }

  /* notification */
  .notication-wrapper {
    padding: 22px 14px 10px;
  }

  /* course */
  .search-section {
    padding: 22px 30px 10px;
  }

  .course-tab-body {
    margin: 15px 0 0;
  }

  .course-tab-body > .row > .col-12 {
    padding: 0;
  }

  /* serch */
  .single-search-item-inner-body-left {
    border-right: 0;
  }

  .single-search-item-heading-icon {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  .single-search-item-heading-icon > img {
    width: 18px;
  }

  .single-search-item-feature-wrapper {
    margin: 10px 0 0;
  }

  .single-search-item-feature {
    margin-bottom: 5px;
  }

  /* .faq-card-section */
  .faq-card-section {
    margin: 30px;
  }

  .ielts-card-section {
    margin: 30px 15px;
  }

  .faq-question-card-section {
    margin: 0 0 30px;
    padding: 30px 30px;
  }

  /* visa-processing-step */
  .single-visa-processing-card-section {
    padding: 0px 10px 10px;
  }

  .single-visa-processing-card-section > .card-info-body {
    padding: 0px 15px 0px;
  }

  /* blog */
  .blog-main-info {
    margin: 0px 0 20px;
  }

  .blog-main-info-txt,
  .single-blog-card .single-blog-txt {
    padding: 0 15px;
  }

  .single-blog-card-img > img {
    width: 100%;
  }

  /* tips-list-section */
  .scholars-list-section.tips-list-section {
    padding: 30px 15px;
  }

  /* search-section-body */
  .single-search-item {
    height: auto;
  }
}

@media screen and (max-width: 767px) {
  .auth-login-wrapper {
    width: 450px;
  }

  .menu ul li {
    padding-right: 15px !important;
  }

  .btn.btn-tab,
  .btn.btn-tab:hover,
  .btn.btn-tab:focus {
    margin-bottom: 10px;
  }

  .scholership-tab-heading {
    margin-bottom: 10px;
  }

  .scholership-advertaisement {
    display: block;
  }

  .scholership-advertaisement > ul > li img {
    width: 145px;
  }

  /* faq */
  .faq-hero-section,
  .apply-section {
    padding: 60px 15px;
    margin: 0;
  }

  /* serch */
  .single-search-item {
    padding: 15px;
  }

  /* visa-processing-step */
  ul.hero-form-list > li {
    width: 100%;
    margin-bottom: 10px;
  }

  .hero-form-list > li > .btn.btn-large,
  .hero-form-list > li > .btn.btn-large:hover,
  .hero-form-list > li > .btn.btn-large:focus {
    top: 0px;
    width: 100%;
  }

  .single-visa-process-title-section {
    margin: 20px 0 0;
    padding: 20px 0 0;
  }

  .apply-button {
    margin-top: 10px;
  }

  .single-visa-process-title-section .btn.btn-large,
  .single-visa-process-title-section .btn.btn-large:hover,
  .single-visa-process-title-section .btn.btn-large:focus {
    width: 100%;
  }

  /* top scholership */
  /* .default-slick-arrow .slick-prev,
  .default-slick-arrow .slick-next {
    top: 87%;
  } */
  .timeline-row.timeline-section .MuiBox-root {
    width: 75%;
  }

  /* .hm-feature-section */
  .hm-feature-list {
    margin: 0;
  }

  .hm-feature-section .profile-info-img {
    text-align: center;
    margin: 0 auto;
  }

  .hm-feature-section .single-profile-info .profile-info-txt {
    margin: 20px auto 0;
  }

  .feature-profile-info.odd,
  .feature-profile-info.even {
    padding: 0;
    border: 0;
    border-bottom: 1px solid var(--border);
  }

  .feature-profile-info:last-child {
    border-bottom: 0;
  }

  .feature-profile-info {
    padding: 30px 15px 30px !important;
  }

  .feature-info-img > img {
    width: 180px;
  }

  .feature-info-text h2 {
    font-size: 20px;
    line-height: 28px;
  }

  .feature-info-text h3 {
    display: none;
  }

  .hm-feature-section {
    padding: 30px 30px 0px;
  }

  .faq-ask-form-img {
    display: none;
  }

  .single-visa-process-step {
    margin-bottom: 20px;
  }

  .single-blog-wrapper {
    margin: 30px;
  }
}

@media screen and (max-width: 575px) {
  .logo-img img {
    width: 100px;
  }

  .card-info-heading {
    align-items: baseline;
    justify-content: flex-start;
    flex-direction: column;
  }

  .card-info-heading-filter {
    margin: 10px 0 0;
  }

  .auth-login-wrapper {
    width: 100%;
    padding: 0;
  }

  .auth-forget-section {
    margin-bottom: 10px;
    flex-direction: column;
    align-items: baseline;
  }

  /* carousel */
  .carousel-control-next {
    left: 55%;
  }

  /* topNavigation */
  .hero-search-form {
    width: 350px;
  }

  /* hero-section */
  .hero-section {
    background: var(--blue) !important;
  }

  .hero-section-body {
    max-width: 100%;
  }

  .hero-section-body > h2 {
    text-align: center;
    max-width: 275px;
    color: var(--white) !important;
    margin: 0 auto 20px !important;
  }

  .hero-section-body.about-section-body > p {
    text-align: center;
    max-width: 275px;
    color: var(--white) !important;
    margin: 0 auto 0px !important;
  }

  .hero-checklist > li > .txt {
    color: var(--white);
  }

  .hero-checklist > li > .icon {
    font-size: 14px;
  }

  ul.hero-checklist > li {
    align-items: flex-start;
  }

  .hero-section-content .col-md-6 {
    padding: 0;
  }

  ul.hero-checklist {
    max-width: 350px;
    margin: 0 auto;
  }

  .hero-form-wrapper-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background: var(--darkBlue);
    margin-bottom: 20px;
  }

  button.btn.hm-hero-btn,
  button.btn.hm-hero-btn:hover,
  button.btn.hm-hero-btn:focus {
    border-radius: 0px !important;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    padding: 15px 20px !important;
  }

  .hero-search-form {
    height: 52px;
  }

  .hero-form-wrapper .subscribe-from {
    margin-bottom: 0px;
  }

  .hero-form-wrapper .hero-search-form {
    width: 100%;
  }

  .hero-checklist .icon.one {
    color: #3aa2f8 !important;
  }

  .hero-checklist .icon.two {
    color: var(--orange);
  }

  .hero-checklist .icon.three {
    color: var(--green);
  }

  ul.hero-checklist > li {
    border-right: 1px solid rgba(255, 255, 255, 0.1);
  }

  /* service section */
  ul.service-card-list > li {
    height: 60px;
    justify-content: flex-start;
  }

  .single-service-img {
    width: 40px;
    height: 40px;
  }

  ul.sop-card-list > li {
    height: auto;
    justify-content: center;
    align-items: center;
  }

  .single-service-img > img {
    width: 16px;
  }

  .single-sop-list .single-sop-img > h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  .single-visa-process-step {
    min-height: auto;
  }

  .single-service-body > h2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  .single-service-body > p {
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
  }

  .single-visa-process-step-body p {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
  }

  /* top scholership */
  .default-slick-arrow .slick-prev,
  .default-slick-arrow .slick-next {
    top: 30%;
    width: 40px;
    height: 40px;
    z-index: 1;
  }

  .default-slick-arrow .slick-prev:before,
  .default-slick-arrow .slick-next:before {
    font-size: 12px;
  }

  /* latest-scholership-section */
  .latest-scholership-section,
  .hm-about-section {
    display: none;
  }

  .sop-about-section {
    display: block;
  }

  /* res-contact-form */
  .res-contact-form .section-title > h2 {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: var(----dark-text-two);
  }

  /* timeline-scholership */
  .timeline-scholership-section {
    margin: 0 0 7px;
  }

  .timeline-scholership-txt.section-text > h3.sub-title,
  .timeline-scholership-txt.section-text > p.para,
  .timeline-scholership-txt.section-text .btn-group {
    display: none;
  }

  .sop-about-section .timeline-scholership-txt.section-text > h3.sub-title,
  .sop-about-section .timeline-scholership-txt.section-text > p.para,
  .sop-about-section .timeline-scholership-txt.section-text .btn-group {
    display: block;
  }

  /* application process */
  .single-card-list.single-circle-card-list {
    width: 100%;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
  }

  .single-circle-card-body {
    text-align: left;
    max-width: 100%;
    margin: 0px 14px 0;
  }

  .res-application-process {
    padding: 30px 14px;
  }

  /*  .hm-student-review */
  .hm-student-review-inner-txt-content {
    padding: 20px 0px 30px;
  }

  .single-review-comment > p {
    font-size: 16px;
    line-height: 25px;
  }

  /* visa-process-step */
  ul.visa-process-step-list > li,
  ul.visa-process-step-list > li:last-child {
    min-width: 100%;
  }

  .visa-process-hero-section-body
    .hero-form-list
    > li
    > .input-field-main
    label {
    color: var(--white);
  }

  .single-visa-process-title-section h2 {
    color: var(--white);
  }

  .single-visa-process-title-section {
    border-top: 1px solid rgba(255, 255, 255, 0.3);
  }

  .single-visa-processing-card-section > .card-info-body {
    padding: 0px 15px 0px;
  }

  /* .about-hm-review */
  .about-hm-review .hm-student-review-inner {
    background: var(--bgOne);
    padding: 0 30px;
  }

  /* blog-section */
  .newsletter-input input {
    width: 250px;
  }

  /* ielts-section */
  .ielts-card-booking-card {
    padding: 30px 15px;
  }
}

@media screen and (max-width: 445px) {
  .hero-form-wrapper .hero-search-form {
    width: 315px;
  }

  .section-text > h2.title {
    font-size: 28px;
    line-height: 36px;
  }

  .responsive-filter-serch .hero-search-form {
    width: 250px !important;
  }

  .timeline-scholership-graph {
    padding: 20px 15px;
    box-shadow: 0px 34px 24px rgb(0 0 0 / 5%);
    border-radius: 8px;
    border: 1px solid #dce6ed;
  }

  .timeline-row .color-check-cell {
    width: 40px;
    height: 10px;
    border-radius: 50px;
  }

  .timeline-row .color-check-cell .icon {
    font-size: 10px;
  }

  .newsletter-input input {
    width: 200px;
  }

  .subscriber-newsletter {
    padding: 15px;
  }

  .sliding-footer-right {
    margin: 8px 0 0;
  }

  .sliding-footer .sliding-footer-content .sliding-live-chat {
    margin-left: 8px !important;
  }
  .sliding-footer .sliding-footer-content .call,
  .sliding-footer .sliding-footer-content .sliding-live-chat {
    font-size: 12px;
  }

  .responsive-filter-serch {
    top: 65px;
  }
}

@media screen and (max-width: 399px) {
  /* hero-section */
  ul.hero-checklist {
    max-width: 300px;
  }

  .hero-form-wrapper .hero-search-form {
    width: 280px;
  }

  .default-slick-arrow .slick-prev,
  .default-slick-arrow .slick-next {
    top: 89%;
  }

  .responsive-filter-serch .hero-search-form {
    width: 230px !important;
  }

  .sliding-footer-content > button > svg {
    display: none;
  }

  .sliding-footer-content .btn.btn-blue,
  .sliding-footer-content .btn.btn-blue:hover,
  .sliding-footer-content .btn.btn-blue:focus {
    font-size: 10px !important;
  }

  .auth-login-body p.auth-subtitle:before,
  .auth-login-body p.auth-subtitle:after {
    width: 75px;
  }

  .carousel-control-prev {
    left: 40%;
  }

  .carousel-control-next {
    left: 50%;
  }

  .timeline-row .empty-cell {
    height: 10px;
  }

  .faq-question-card-section .subscribe-from {
    display: block;
    margin: 0 !important;
  }

  /* .subscribe-from {
    flex-direction: column;
  } */
  .newsletter-input input {
    width: 100%;
  }
}

@media screen and (max-width: 350px) {
  .hero-form-wrapper .hero-search-form {
    width: 230px;
  }

  ul.service-card-list > li,
  ul.service-card-list > li:last-child {
    min-width: 100%;
  }

  .auth-login-body p.auth-subtitle:before,
  .auth-login-body p.auth-subtitle:after {
    width: 65px;
  }

  .default-slick-arrow .slick-prev,
  .default-slick-arrow .slick-next {
    top: 87%;
  }
}
